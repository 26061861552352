import { PLATFORMS, TRAFFIC_SOURCES } from 'mm-commercial-utils';
import { InjectorStrategy } from './strategies/strategies';

const PLACEHOLDER_ID_PREFIX = 'MM_DISPLAY_MANAGER_AD';
export const DYNAMIC_ASSET_PLACEHOLDER_PREFIX = `${PLACEHOLDER_ID_PREFIX}_DYNAMIC`;
export const STATIC_ASSET_PLACEHOLDER_PREFIX = `${PLACEHOLDER_ID_PREFIX}_STATIC`;

export const SUPPORTED_PLATFORMS = [PLATFORMS.DESKTOP, PLATFORMS.MOBILE];
export const SUPPORTED_TRAFFIC_SOURCES = [TRAFFIC_SOURCES.PAID, TRAFFIC_SOURCES.ORGANIC, TRAFFIC_SOURCES.INFLUENCER, TRAFFIC_SOURCES.DIRECT];
export const DEFAULT_PLATFORM = PLATFORMS.MOBILE;
export const DEFAULT_TRAFFIC_SOURCE = TRAFFIC_SOURCES.DIRECT;
export const NO_ADS_TAG = 'no-ads';
export const NO_CAMPAIGN_MANAGER = 'no-campaign-manager';

export type PageData = {
  country: string;
  state: string;
  platform: string;
  operatingSystem: string;
  trafficSource: string;
  tags: string[];
  distributionChannels: string[];
  language: string;
  mmUserIdentifier: number;
  experiment: string;
  pageType: string;
  articleId: string;
  trafficSourceAndId: string;
  author: string;
  sessionid: string;
  affiliate?: string;
}

export enum StaticAssetType {
  WidgetDirect = 'widget-direct',
  Logomorph = 'logomorph',
  Display = 'display'
}

export type CampaignItem = {
  assetType: StaticAssetType,
  assetId: string,
  campaignGroupId: string,
  campaignId: string,
}

export interface InjectorItemAsset {
  html: string;
  script?: string;
  dataId?: string;
  index?: number;
  selector?: string;
}

export interface PredefinedAssetsByPosition {
  [AssetPosition.Top]?: PredefinedDynamicAsset,
  [AssetPosition.Middle]?: PredefinedDynamicAsset,
  [AssetPosition.Bottom]?: PredefinedDynamicAsset,
}

export enum AssetPosition {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export interface InjectorItem {
  payload: InjectorItemAsset;
  callback?: (placeholderId: string) => void;
  assetType: StaticAssetType;
}

export interface InjectorWidgetItem {
  payload: InjectorItemAsset;
  assetType: StaticAssetType;
  assetId: string;
  assetPosition: AssetPosition;
}

export interface VideoLogoInjectorItem {
  payload: string;
  assetType: StaticAssetType;
  assetId: string;
  campaignGroupId: string;
  campaignId: string;
}

export interface StaticAssetsInjectorConfig {
  assets: InjectorItem[];
}

export interface PredefinedDynamicAsset {
  index: number;
  item: InjectorWidgetItem;
  isScriptInjected?: boolean;
  campaignGroupId: string;
  campaignId: string;
}

export interface DynamicAssetsInjectorConfig {
  firstAdPosition: number;
  blocksBetweenAds: number;
  maxAssets: number;
  rootCssSelector?: string;
  predefinedAssets: PredefinedAssetsByPosition;
  assets: InjectorItem[];
}

export class Injector {
  private strategy: InjectorStrategy;

  constructor(strategy: InjectorStrategy) {
    this.strategy = strategy;
  }

  setStrategy(strategy: InjectorStrategy) {
    this.strategy = strategy;
  }

  async injectDynamic(
    dynamicAssetsConfig: DynamicAssetsInjectorConfig,
    widgetEmbedEventCallback: (campaign: CampaignItem) => void,
    widgetImpressionEventCallback: (campaign: CampaignItem) => void,
    pageData: PageData,
    sitePolicyId: string,
    callback?: (placeholders: string[]) => void,
  ) {
    return this.strategy.injectDynamic(dynamicAssetsConfig, widgetEmbedEventCallback, widgetImpressionEventCallback, pageData, sitePolicyId, callback);
  }

  injectStatic(assets: StaticAssetsInjectorConfig) {
    this.strategy.injectStatic(assets);
  }

  // TODO: remove - should not be here
  injectVideoLogo(isPlatform: boolean, asset: VideoLogoInjectorItem, logomorphEmbedEventCallback: (logomorphItem : CampaignItem) => void) {
    if (this.strategy.injectVideoLogo) {
      this.strategy.injectVideoLogo(asset, logomorphEmbedEventCallback, isPlatform);
    }
  }

  getPageData(isPlatform: boolean) {
    return this.strategy.getPageData(isPlatform);
  }

  setShouldEnableAds(enableAdsOnFirstContent: boolean) {
    this.strategy.setShouldEnableAds(enableAdsOnFirstContent);
  }
}
