import { InjectorItem } from '../../../Injector';

export class StaticPlaceholder {
    node: Element;

    index: number;

    asset: InjectorItem;

    constructor(staticPlaceholderElement: Element, index: number, asset: InjectorItem) {
      this.node = staticPlaceholderElement;
      this.index = index;
      this.asset = asset;
    }

    setPlaceholder() {
      const { payload } = this.asset;
      const { dataId } = payload;
      const placeholderId = `${dataId}_${this.index}`;
      this.node.insertAdjacentHTML('afterbegin', `<div id="${placeholderId}">`);
      return placeholderId;
    }
}
