declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
        POLICY_GTM_CONTAINER_ID: string;
        PROMOTION_GTM_CONTAINER_ID: string;
    }
}

type GtmContainersSubscription = string[];

export class GtmService {
    containers: string[] = [];

    subscriptions: Map<GtmContainersSubscription, Set<() => void>> = new Map();

    private static instance: GtmService;

    static getInstance() {
      if (!this.instance) {
        this.instance = new GtmService();
      }
      return this.instance;
    }

    subscribeToContainers = (gtmContainerSubscription: GtmContainersSubscription, callback: () => void) => {
      const currentSubscriptions = this.subscriptions.get(gtmContainerSubscription) || new Set();
      this.subscriptions.set(gtmContainerSubscription, currentSubscriptions.add(callback));
    };

    pushToDataLayer(object: Record<string, unknown>, event?: string) {
      window.dataLayer.push({
        ...object,
        ...(!!event && { event }),
      });
    }

    registerGTMContainer = (containerID: string) => {
      this.containers.push(containerID);
      this.callContainerSubscriptions();
    };

    private callContainerSubscriptions = () => {
      const gtmContainerSubscriptions = Array.from(this.subscriptions.entries());
      gtmContainerSubscriptions.forEach(this.callContainerSubscription);
    };

    private callContainerSubscription = ([gtmContainerSubscription, subscriptionCallbacks]: [GtmContainersSubscription, Set<() => void>]) => {
      if (gtmContainerSubscription.every(containerId => this.containers.includes(containerId))) {
        subscriptionCallbacks.forEach(subscriptionCallback => {
          subscriptionCallback();
          subscriptionCallbacks.delete(subscriptionCallback);
        });
        this.subscriptions.set(gtmContainerSubscription, new Set());
      }
    };

    loadScript(containerId: string) {
      /* eslint-disable */
        (function (w, d, s, l, i) {
            //@ts-ignore
            w[l] = w[l] || [];
            //@ts-ignore
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            //@ts-ignore
            j.async = true;
            //@ts-ignore
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            //@ts-ignore
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', containerId);
        /* eslint-enable */
    }
}
