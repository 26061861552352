import { createUniqueIdentifier } from 'mm-commercial-utils';
import { logger } from '../../utils/logger';
import { SESSION_ENDPOINT_QA } from './tracking.constants';

export type TrackingMethod = (data: Record<string, unknown>) => void;

function getURLObject(): URL {
  return new URL(window.location.href);
}

export function getHostName() {
  return getURLObject().hostname;
}

export function getURLInformation() {
  const urlObject = getURLObject();
  return {
    url: `${urlObject.hostname}${urlObject.pathname}`,
    country: urlObject.searchParams.get('geo'),
    medium: urlObject.searchParams.get('utm_medium'),
    source: urlObject.searchParams.get('utm_source'),
    adSetId: urlObject.searchParams.get('utm_campaign'),
  };
}

export function sendBeacon(endpoint: string, payload: Record<string, unknown>) {
  navigator.sendBeacon(endpoint, JSON.stringify(payload));
}

export function sendSessionData(payload: Record<string, unknown>) {
  sendBeacon(SESSION_ENDPOINT_QA, payload);
}

export function defaultTrackingMethod(data: Record<string, unknown>) {
  const mmDisplayEvent = new CustomEvent('mmDisplayImpression', { detail: data });
  window.dispatchEvent(mmDisplayEvent);
  logger.table('Impression tracking data', data);
}

export function basicTrackingData(data: { adUnitPath: string; fill: boolean; elementId: string }) {
  const { adUnitPath, fill, elementId } = data;
  const mmDisplayEvent = new CustomEvent('displayManagerAdImpression', { detail: { adUnitPath, fill, elementId } });
  window.dispatchEvent(mmDisplayEvent);
}

export function rumSessionTracking() {
  sendSessionData({
    ...getURLInformation(),
    sessionId: createUniqueIdentifier(),
  });
}
