import { BidderConfig, DEFUALT_LAZY_LOAD_THRESHOLD, DfpKeyValues, Sticky } from 'ads-manager';
import { formatSitePolicy } from '../formatters/formatters';

export const DEFAULT_HEADER_BIDDING_CONFIGURATION = {
  prebid: null,
  a9: null,
};

export enum SelectorType {
  TagName = 'tagName',
  ClassName = 'className',
  Id = 'id'
}

export const SITE_POLICY_DEFAULTS = {
  enableAdsOnFirstContent: true,
  headerBiddingConfiguration: DEFAULT_HEADER_BIDDING_CONFIGURATION,
  name: '',
  id: '',
  priority: 1000,
  enableAds: true,
  enableWidgets: true,
  lazyLoadThreshold: DEFUALT_LAZY_LOAD_THRESHOLD,
  assets: {
    dynamic: {
      adUnits: [],
      maxAssets: 99,
      blocksBetweenAds: 2,
      firstAdPosition: 3,
      rootSelector: [{ type: SelectorType.TagName, value: 'body', position: 0 }],
      sizes: { desktop: [], mobile: [] },
    },
    static: {
      adUnits: [],
    },
  },
};

export type AdSizes = [number, number][];

export interface Selector {
  type: SelectorType;
  value: string;
  position?: number;
}

export interface Sizes {
  desktop: AdSizes;
  mobile: AdSizes;
}

interface Refresh {
  enable: boolean;
  interval: number;
  viewableThreshold: number;
  limit: number;
}

export interface RawAdUnit {
  templateId: string;
  path: string;
  refresh: Refresh;
  sticky?: Sticky;
  sizes: Sizes;
  selector: Selector[];
}

export interface AdUnit {
  templateId: string;
  path: string;
  refresh: Refresh;
  sticky?: Sticky;
  sizes: Sizes;
  selector: Selector[];
  dfpKeyValues: DfpKeyValues;
}

export interface DynamicAssets {
  rootSelector?: Selector[];
  maxAssets: number;
  firstAdPosition: number;
  blocksBetweenAds: number;
  adUnits: AdUnit[];
  sizes: Sizes;
}

export interface StaticAssets {
  adUnits: AdUnit[];
}

interface Assets {
  dynamic: DynamicAssets;
  static: StaticAssets;
}

export interface HeaderBiddingConfiguration {
  prebid: { bidders: { video: BidderConfig[]; banner: BidderConfig[] } } | null;
  a9: { video: { accountID: string }; banner: { accountID: string } } | null;
}

export interface RawDynamicAssets {
  rootSelector?: Selector[];
  maxAssets: number;
  firstAdPosition: number;
  blocksBetweenAds: number;
  adUnits: RawAdUnit[];
  sizes: Sizes;
}

export interface RawStaticAssets {
  adUnits: RawAdUnit[];
}

export interface RawAssets {
  dynamic: RawDynamicAssets;
  static: RawStaticAssets;
}

export interface RawSitePolicy {
  name: string;
  id: string;
  priority: number;
  assets: RawAssets;
  enableWidgets: boolean;
  enableAds: boolean;
  lazyLoadThreshold: number;
}

export interface SitePolicy {
    name: string;
    id: string;
    priority: number;
    assets: Assets;
    enableWidgets: boolean;
    enableAds: boolean;
    enableAdsOnFirstContent: boolean;
    lazyLoadThreshold: number;
    headerBiddingConfiguration: HeaderBiddingConfiguration;
}

export const getSitePolicyWithHighestPriority = (sitePolicies: SitePolicy[]) => sitePolicies
  .reduce((max, sitePolicy) => (max.priority < sitePolicy.priority ? max : sitePolicy));

export class SitePolicyService {
  private static instance: SitePolicyService;

  sitePolicies: SitePolicy[] = [];

  headerBidding: HeaderBiddingConfiguration = {
    prebid: null,
    a9: null,
  };

  static getInstance() {
    if (!this.instance) {
      this.instance = new SitePolicyService();
    }
    return this.instance;
  }

  getSitePolicy() {
    if (this.sitePolicies.length === 0) {
      return {
        ...SITE_POLICY_DEFAULTS,
        headerBiddingConfiguration: { ...this.headerBidding },
      };
    }
    return {
      ...getSitePolicyWithHighestPriority(this.sitePolicies),
      headerBiddingConfiguration: { ...this.headerBidding },
    };
  }

  setSitePolicy(sitePolicy: RawSitePolicy) {
    this.sitePolicies.push(formatSitePolicy(sitePolicy));
  }

  setHeaderBidding(headerBiddingConfiguration: HeaderBiddingConfiguration) {
    this.headerBidding = headerBiddingConfiguration;
  }
}
