import {
  AdsManager,
  AdUnit as AdsManagerAdUnit,
  DfpKeyValues,
  getModules,
  getSettings,
  initAdsManager,
  Sticky,
  VideoAuctionAdUnit,
} from 'ads-manager';
import { getUserPlatform, PLATFORMS } from 'mm-commercial-utils';
import { PageData } from 'injector';
import { AdSizes, AdUnit, DynamicAssets, SitePolicy } from './sitePolicy.service';
import { EventStreamer } from '../eventStreaming/EventStreamer';

const DEFAULT_DFP_VALUES_DELIMITER = ',';

// TODO: should be removed when possible
function formatDistributionChannels(distributionChannels: string[]) {
  return distributionChannels.map(channel => channel.replace('-', ''));
}

const DEFAULT_AD_OPTIONS: Partial<AdsManagerAdUnit> = {
  refresh: false,
  triggerAuction: true,
};

interface InsertPlacementOptions {
  placeholderId: string;
  size: AdSizes;
  adUnitPath: string;
  enableRefresh: boolean;
  refreshInterval: number;
  refreshLimit: number;
  viewableThreshold: number;
  triggerAuction: boolean;
  sticky: Sticky;
  dfpKeyValues?: DfpKeyValues;
}

function getDisplayManagerOptionsFromSitePolicy(sitePolicy: SitePolicy) {
  const { headerBiddingConfiguration, lazyLoadThreshold } = sitePolicy;
  const {
    prebid,
    a9,
  } = headerBiddingConfiguration;
  return {
    lazyLoadThreshold,
    shouldLoadGPTScript: INJECTOR_STRATEGY === 'nonPlatform',
    prebidConfig: {
      bidders: {
        banner: prebid?.bidders.banner || [],
        video: prebid?.bidders.video || [],
      },
      useBidCache: true,
      consentManagement: {
        gdpr: {
          cmpApi: 'iab',
          timeout: 20000,
          defaultGdprScope: true,
          allowAuctionWithoutConsent: true,
        },
        usp: {
          timeout: 100,
        },
      },
    },
    a9Config: a9,
  };
}

function getModuleOptions(pageData: PageData, sitePolicy: SitePolicy) {
  const {
    language,
    pageType,
    articleId,
    distributionChannels,
    trafficSource,
    trafficSourceAndId,
    tags,
    experiment,
    author,
    affiliate,
    sessionid,
  } = pageData;
  const { id: policy_id } = sitePolicy;
  const formattedDistributionChannels = formatDistributionChannels(distributionChannels)
    .join(DEFAULT_DFP_VALUES_DELIMITER);
  return {
    dfpKeyValuesSettings: {
      customParams: {
        language,
        contentTags: formattedDistributionChannels,
        pageType,
        articleId,
        distributionChannels: formattedDistributionChannels,
        commercialtags: tags.join(DEFAULT_DFP_VALUES_DELIMITER),
        trafficSource,
        trafficSourceAndId,
        policy_id,
        session_id: EventStreamer.sessionId,
        experiment,
        author,
        affiliate,
        ad_type: 'display',
        sessionid,
      },
      queryParams: [
        ['utm_source', 'utmSource'],
        ['utm_medium', 'utmMedium'],
        ['utm_campaign', 'ad_set_id'],
      ] as [string, string][],
    },
  };
}

function insertPlacement(insertPlacementOptions: Partial<InsertPlacementOptions>) {
  const adsManager = AdsManager.getInstance();
  const {
    placeholderId = '',
    size = [],
    adUnitPath = '',
    enableRefresh,
    refreshInterval,
    refreshLimit,
    viewableThreshold,
    triggerAuction = true,
    sticky,
    dfpKeyValues,
  } = insertPlacementOptions;
  return adsManager.insertPlacement(
    placeholderId,
    size,
    adUnitPath,
    {
      refresh: enableRefresh,
      refreshInterval,
      refreshLimit,
      viewableThreshold,
      triggerAuction,
      sticky,
      dfpKeyValues,
    },
  );
}

function createInsertPlacementOptionsFromAdUnit(placeholderId: string, adUnit: AdUnit) {
  const {
    sizes,
    path,
    refresh,
    sticky,
    dfpKeyValues,
  } = adUnit;
  const {
    interval,
    enable,
    limit,
    viewableThreshold,
  } = refresh;
  const platform = getUserPlatform() === PLATFORMS.MOBILE ? PLATFORMS.MOBILE : PLATFORMS.DESKTOP;
  const platformSizes = sizes[platform];
  return {
    placeholderId,
    size: platformSizes,
    adUnitPath: path,
    enableRefresh: enable,
    refreshInterval: interval * 1000,
    refreshLimit: limit,
    viewableThreshold,
    sticky,
    dfpKeyValues,
  };
}

function createInsertPlacementOptionsFromDynamicAssets(placeholders: string[], dynamicAssets: DynamicAssets) {
  const { adUnits } = dynamicAssets;
  return placeholders.map((placeholderId, index) => {
    const adUnit = adUnits[index % adUnits.length];
    return createInsertPlacementOptionsFromAdUnit(placeholderId, adUnit);
  });
}

export class AdsManagerService {
    private static instance: AdsManagerService;

    static getInstance() {
      if (!this.instance) {
        this.instance = new AdsManagerService();
      }
      return this.instance;
    }

    populateDynamicPlaceholders = (placeholders: string[], dynamicAssets: DynamicAssets) => {
      [...createInsertPlacementOptionsFromDynamicAssets(placeholders, dynamicAssets)].forEach(options => options.size && options.size.length && insertPlacement(options));
    };

    populateStaticPlaceholder = (adUnit: AdUnit) => {
      return (placeholderId: string) => {
        const insertPlacementOptions = createInsertPlacementOptionsFromAdUnit(placeholderId, adUnit);
        // TODO: filter out not relevant assets on the sitePolicy
        if (insertPlacementOptions?.size.length > 0) {
          insertPlacement(insertPlacementOptions);
        }
      };
    };

    async initWithSitePolicyAndPageData(sitePolicy: SitePolicy, pageData: PageData) {
      const { dfpKeyValuesSettings } = getModuleOptions(pageData, sitePolicy);
      const options = getDisplayManagerOptionsFromSitePolicy(sitePolicy);
      await initAdsManager(getModules({ dfpKeyValuesSettings }), getSettings(options), pageData, INJECTOR_STRATEGY === 'platform');
    }

    async requestAds(adUnitsForAuction: AdsManagerAdUnit[]) {
      const adsManager = AdsManager.getInstance();
      await adsManager.requestAds(adUnitsForAuction);
    }

    async runVideoAuction({ videoAuctionAdUnits, timeout }: VideoAuctionRequestConfig) {
      return new Promise(resolve => {
        const adsManager = AdsManager.getInstance();
        const prebidBidsBackHandler = (videoUrls: string[]) => resolve(videoUrls);
        adsManager.insertVideo(videoAuctionAdUnits, timeout, prebidBidsBackHandler);
      });
    }

    async renderDisplayAd(adUnitPath: string, sizes: number[][], placeholderId: string) {
      const adsManager = AdsManager.getInstance();
      const adUnit = adsManager.insertPlacement(placeholderId, sizes, adUnitPath, DEFAULT_AD_OPTIONS);
      if (adUnit) {
        adsManager.requestAds([adUnit]);
        return {
          slot: adUnit.slot,
          destroy: () => adsManager.destroyAdUnit(adUnit),
        };
      }
      return new Error('Something went wrong!');
    }
}

interface VideoAuctionRequestConfig {
    videoAuctionAdUnits: VideoAuctionAdUnit[];
    timeout: number;
}
