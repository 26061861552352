import { CampaignItem, PredefinedDynamicAsset } from '../../../Injector';
import { BLOCK_TYPES } from '../../strategies.constants';

const BOLD_FONT_WEIGHT_VALUES = ['bold', '500', '600', '700', '800', '900', 'bolder'];
const TITLE_TAG_NAMES = ['H1', 'H2', 'H3', 'H4', 'H5'];
const SKIPPED_BLOCK_TYPES: string[] = [BLOCK_TYPES.TITLE, BLOCK_TYPES.IMAGE, BLOCK_TYPES.PICTURE];
const MINIMUM_CONTENT_AREA = 60;
export type BlockType = typeof BLOCK_TYPES[keyof typeof BLOCK_TYPES];
export const ALLOWED_TAG_NAMES: string[] = ['P', ...TITLE_TAG_NAMES, 'IMG', 'TABLE', 'UL', 'PICTURE'];
const MINIMUM_CONTENT_LENGTH = 175;
const FLOAT_DIRECTIONS = ['left', 'right'];

export interface InitialDynamicPositionState {
  index: number;
  skipCorrection: number;
  charsBetween: number;
  floatStart: number;
  positions: number[];
  firstAdPosition: number;
  blocksBetweenAds: number;
  contentBlocksLength: number;
  lastFoundPositionIndex: number;
  lastFoundPositionTop: number;
}

export interface DynamicPositionState extends InitialDynamicPositionState {
  block: ContentBlock;
  previousBlock?: ContentBlock;
  nextBlock?: ContentBlock;
}

export interface ContentBlock {
  elementRef: HTMLElement;
  contentLength: number;
  index: number;
  top: number;
  offset: number;
  height: number;
  width: number;
  blockType: string;
}

function isTitle(element: HTMLElement) {
  const isTitleByTagName = () => TITLE_TAG_NAMES.includes(element.tagName);
  const isStrong = () => element.childElementCount === 1 && element.firstElementChild?.tagName === 'STRONG' && element.firstElementChild?.textContent?.length === element.textContent?.length;
  const isBTag = () => element.childElementCount === 1 && element.firstElementChild?.tagName === 'B' && element.firstElementChild?.textContent?.length === element.textContent?.length;
  const isBold = () => BOLD_FONT_WEIGHT_VALUES.includes(element.style.fontWeight);
  return isTitleByTagName() || isStrong() || isBold() || isBTag();
}

export function isValidImage(contentBlock: ContentBlock) {
  const contentBlockArea = contentBlock.width * contentBlock.height;
  const windowArea = window.innerWidth * window.innerHeight;
  return contentBlock.blockType === BLOCK_TYPES.IMAGE && contentBlockArea > windowArea * 0.05;
}

export function getBlockType(element: HTMLElement) {
  if (isTitle(element)) {
    return BLOCK_TYPES.TITLE;
  }
  if (element.tagName === 'P') {
    return BLOCK_TYPES.TEXT;
  }
  if (element.tagName === 'IMG') {
    return BLOCK_TYPES.IMAGE;
  }
  if (element.tagName === 'PICTURE') {
    return BLOCK_TYPES.PICTURE;
  }
  return BLOCK_TYPES.BLOCK;
}

export function mapHTMLElementToContentBlock(element: HTMLElement, index: number) {
  const {
    top,
    y,
    width,
    height,
  } = element.getBoundingClientRect();
  const offsetParentElement = element.offsetParent;
  const isElementWrapped = offsetParentElement?.getBoundingClientRect().top === top && offsetParentElement?.getBoundingClientRect().height === height;
  const elementRef = (offsetParentElement && isElementWrapped ? offsetParentElement : element) as HTMLElement;
  const blockType = getBlockType(elementRef);
  return {
    elementRef,
    index,
    contentLength: element.innerText?.length || 0,
    top,
    offset: y,
    width,
    height,
    blockType,
  };
}

export function isNotEmptyContentBlock(contentBlock: ContentBlock) {
  const {
    elementRef,
    contentLength,
    height,
  } = contentBlock;
  const isNotEmptyParagraph = elementRef.nodeName === 'P' ? contentLength > 0 : true;
  return height > 0 && isNotEmptyParagraph;
}

export function isNotInsideTwitter(contentBlock: ContentBlock) {
  const { elementRef } = contentBlock;
  return elementRef.closest('.twitter-tweet') === null;
}

export function isNotInsideSemanticElement(contentBlock: ContentBlock) {
  const { elementRef } = contentBlock;
  return elementRef.closest('figure, picture, aside, header, footer, table, ul') === null;
}

function isFirstIndex({
  index,
  firstAdPosition,
  skipCorrection,
  lastFoundPositionIndex,
}: DynamicPositionState) {
  return lastFoundPositionIndex === 0 && (index - skipCorrection === firstAdPosition);
}

function indexWithAppliedCorrections({
  index,
  lastFoundPositionIndex,
  skipCorrection,
}: DynamicPositionState) {
  return lastFoundPositionIndex > 0 ? index - lastFoundPositionIndex - skipCorrection : index - skipCorrection;
}

function isValidBlocksBetween(dynamicPositionState: DynamicPositionState) {
  const {
    index,
    firstAdPosition,
    blocksBetweenAds,
  } = dynamicPositionState;
  return (index > firstAdPosition && indexWithAppliedCorrections(dynamicPositionState) % blocksBetweenAds === 0);
}

export function isMinimumContentBetween(dynamicPositionState: DynamicPositionState) {
  const { charsBetween, blocksBetweenAds, index, firstAdPosition, lastFoundPositionIndex } = dynamicPositionState;
  return index === firstAdPosition || (index > firstAdPosition && !lastFoundPositionIndex) || charsBetween > (MINIMUM_CONTENT_LENGTH * blocksBetweenAds);
}

export function isExceedingMaximumArea({
  block,
  lastFoundPositionTop = 0,
}: DynamicPositionState) {
  const { innerHeight: windowHeight } = window;
  const contentArea = (block.top - lastFoundPositionTop) / (windowHeight / 100);
  return lastFoundPositionTop && contentArea < MINIMUM_CONTENT_AREA;
}

function isAllowedBlock({ previousBlock }: DynamicPositionState) {
  return !SKIPPED_BLOCK_TYPES.includes(previousBlock?.blockType || '');
}

function isAtTheEnd({
  index,
  contentBlocksLength,
}: DynamicPositionState) {
  return index > contentBlocksLength - 1;
}

function isValidPosition(dynamicPositionState: DynamicPositionState) {
  return isMinimumContentBetween(dynamicPositionState) || (
    (isFirstIndex(dynamicPositionState) || isValidBlocksBetween(dynamicPositionState))
    && !isAtTheEnd(dynamicPositionState)
  );
}

function getClosestFloatingClientRect(elementRef: HTMLElement) {
  let floatingElement = elementRef;
  do {
    if (FLOAT_DIRECTIONS.includes(window.getComputedStyle(floatingElement).float)) {
      return floatingElement.getBoundingClientRect();
    }
    floatingElement = (floatingElement.parentElement || floatingElement.parentNode) as HTMLElement;
  } while (floatingElement !== null && floatingElement.nodeType === 1);

  return null;
}

export function isFloating({
  floatStart,
  block,
  previousBlock,
  nextBlock,
}: DynamicPositionState) {
  let floatEnd = floatStart !== 0 ? floatStart : 0;
  if (floatEnd === 0) {
    const isBeforePreviousBlock = previousBlock && block.offset < previousBlock.offset;
    const isAfterNextBlock = nextBlock && block.offset > nextBlock.offset;
    if (isBeforePreviousBlock || isAfterNextBlock) {
      const floatingRect = getClosestFloatingClientRect(block.elementRef);
      floatEnd = floatingRect ? floatingRect.top + floatingRect.height : floatEnd;
    }
    return floatEnd;
  }
  return block.offset > floatEnd ? 0 : floatEnd;
}

export function shouldBeSkipped(dynamicPositionState: DynamicPositionState) {
  return isValidPosition(dynamicPositionState) && (isExceedingMaximumArea(dynamicPositionState) || !isAllowedBlock(dynamicPositionState) || dynamicPositionState.floatStart > 0);
}

export function isValidPlacement(dynamicPositionState: DynamicPositionState) {
  return isValidPosition(dynamicPositionState) && !shouldBeSkipped(dynamicPositionState) && !isAtTheEnd(dynamicPositionState);
}

export function updateDynamicPositionState(dynamicPositionState: DynamicPositionState) {
  const currentState = { ...dynamicPositionState };
  if (shouldBeSkipped(currentState)) {
    currentState.skipCorrection += 1;
  }
  currentState.floatStart = currentState.previousBlock ? isFloating(currentState) : 0;

  if (isValidPlacement(currentState)) {
    currentState.charsBetween = 0;
    currentState.skipCorrection = 0;
    return {
      ...currentState,
      positions: [...currentState.positions, currentState.index],
      lastFoundPositionIndex: currentState.index,
      lastFoundPositionTop: currentState.block.top,
    };
  }
  currentState.charsBetween += currentState.block.contentLength;
  return currentState;
}

export function getWidgetCampaignsItems(predefinedWidgetAssets: PredefinedDynamicAsset[]) {
  const campaignsItemsMap = {} as Record<string, CampaignItem>;
  predefinedWidgetAssets.forEach(widgetAsset => {
    const { item, campaignGroupId, campaignId } = widgetAsset;
    const { assetId, assetType } = item;
    campaignsItemsMap[campaignId] = {
      assetId,
      assetType,
      campaignGroupId,
      campaignId,
    };
  });
  return campaignsItemsMap;
}
