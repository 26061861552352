import { clientAPI } from 'mm-commercial-utils';
import { LogomorphCampaign, WidgetDirectCampaign } from '../campaign/campaign';

const htmlToElement = (html: string) => {
  const template = document.createElement('template');
  template.innerHTML = html.trim();

  return template.content.firstElementChild as HTMLElement;
};

const createScriptInjector = (script: HTMLElement) => {
  return `document.head.appendChild((function () { const s = document.createElement('script'); s.innerHTML = \`${script.innerHTML}\`; ${Array.from(script.attributes)
    .map(attr => `s.setAttribute('${attr.name}', '${attr.value}');`)
    .join('')} return s;})());`;
};

export const stripScriptsFromPayload = (payload: string) => {
  const wrappedPayload = `<div>${payload}</div>`;
  const element = htmlToElement(wrappedPayload);

  const scriptElements = Array.from(element.getElementsByTagName('script'));
  const script = scriptElements.map(createScriptInjector)
    .join('');

  scriptElements.forEach(scriptElement => scriptElement.remove());
  const html = element.innerHTML;

  return {
    html,
    script,
  };
};

const findIndexAccordingToPolicy = (blockName: string, index: number, array: string[]) => {
  const articleLength = array.length;
  const minIndexToInject = Math.floor(articleLength / 2);
  const maxIndexToInject = articleLength - 3;

  const isPreviousBlockInlineText = array[index - 1] === 'inline-text';
  const isCurrentBlockInlineTextOrTitle = blockName === 'inline-text' || blockName === 'title';
  const isValidIndexToInject = index >= minIndexToInject && index <= maxIndexToInject;

  return isValidIndexToInject && isPreviousBlockInlineText && isCurrentBlockInlineTextOrTitle;
};

export const findBlockIndexToInject = (blockNames: string[]) => {
  const fallbackIndex = Math.floor(blockNames.length / 2);
  const blockIndex = blockNames.findIndex(findIndexAccordingToPolicy);

  return blockIndex !== -1 ? blockIndex : fallbackIndex;
};

export const injectLogomorphCampaign = (campaign: LogomorphCampaign) => {
  const CDN_ENDPOINT = 'https://images.mmctsvc.com/video/upload';
  clientAPI.injectVideoLogo({ videoPath: `${CDN_ENDPOINT}/${campaign.asset.payload}` });
};

export const injectWidgetDirectCampaign = (campaign: WidgetDirectCampaign, index: number, dataId: string) => {
  const {
    html,
    script,
  } = stripScriptsFromPayload(campaign.asset.payload);
  clientAPI.injectArticleBlockByIndexArray([{
    html,
    dataId,
    index,
    script,
  }]);
};
