type PrintType = 'log' | 'table';
export const PRINT_TYPES: Record<string, PrintType> = {
  LOG: 'log',
  TABLE: 'table',
};
const PRINT_STYLES = {
  GROUP_NAME: 'font-size: 24px; font-weight: bold; color: blue;',
  GROUP_TITLE: 'font-size: 18px; font-weight: bold; color: orange;',
};

const shouldCallConsole = () => window.console && window.location.search && window.location.search.match(/displayDebug=/g);
const getLabel = (label: string) => `%cDisplayManager: %c${label}`;
const callConsole = (type: PrintType, label: string, args?: []) => {
  if (args) {
    // eslint-disable-next-line no-console
    console.groupCollapsed(getLabel(label), PRINT_STYLES.GROUP_NAME, PRINT_STYLES.GROUP_TITLE);
    // eslint-disable-next-line no-console
    args.forEach((arg: []) => console[type](arg));
    // eslint-disable-next-line no-console
    console.groupEnd();
  } else {
    // eslint-disable-next-line no-console
    console.log(label);
  }
};

interface Logger {
  log: (label: string, args?: unknown) => void;
  error: (label: string, args: unknown[]) => void;
  table: (label: string, args: unknown[] | Record<string, unknown>) => void;
  validate: (label: string, args: [], printType: PrintType) => void;
}

const missingParam = ([paramKey, paramValue]: [string, unknown]) => (typeof paramValue === 'undefined' ? paramKey : false);
export const logger: Logger = {
  log: (label: string, ...args: []) => {
    if (shouldCallConsole()) {
      callConsole(PRINT_TYPES.LOG, label, [...args]);
    }
  },

  error: (label: string) => {
    if (shouldCallConsole()) {
      callConsole(PRINT_TYPES.ERROR, label);
    }
  },

  table: (label: string, ...args: []) => {
    if (shouldCallConsole()) {
      callConsole(PRINT_TYPES.TABLE, label, [...args]);
    }
  },

  validate: (label: string, data: [], printType: PrintType) => {
    const missingParams = Object.entries(data).filter(missingParam);
    const loggerMethod = logger[printType];
    if (missingParams.length) {
      loggerMethod(`${label} - missing params`, missingParams);
    } else {
      loggerMethod(label, data);
    }
  },
};
