import { AdUnit } from '..';
import { HeaderBiddingService } from '../headerBidding/HeaderBiddingService';
import { logger } from '../utils/logger';
import { VideoAdUnit } from '../adUnits/VideoAdUnit';
import { PageData } from '../adsManager/AdsManager';

export class RequestManager {
  adServerRequestSent = false;

  providers: Record<string, HeaderBiddingService> = {};

  onBiddersReady: (adUnits: AdUnit[]) => void = () => logger.log('bidders ready');

  private getProviders = () => Object.values(this.providers);

  private biddersBack = (bidderName: string, adUnits: AdUnit[]) => {
    if (this.allBiddersBack()) {
      this.sendAdServerRequest(adUnits);
    }
  };

  sendAdServerRequest(adUnits: AdUnit[]) {
    this.onBiddersReady(adUnits);
  }

  initProviders(hbServices: Record<string, HeaderBiddingService>, onBiddersReady: (adUnits: AdUnit[]) => void) {
    this.onBiddersReady = onBiddersReady;
    this.providers = hbServices;
    this.getProviders().forEach((provider: HeaderBiddingService) => {
      provider.setProps({ biddersBackHandler: this.biddersBack });
    });
  }

  setAdUnits = (adUnits: AdUnit[]) => this.getProviders().forEach(provider => provider.registerAdUnits(adUnits));

  setVideoAdUnits = async (videoAdUnits: VideoAdUnit[], timeout: number, bidsBackHandler: (bids: string[]) => void) => {
    this.getProviders().forEach(provider => provider.sendVideoBidsRequest && provider.sendVideoBidsRequest(videoAdUnits, timeout, bidsBackHandler));
  };

  allBiddersBack = () => this.getProviders().every(provider => provider.biddersBack);

  initServices = (pageData: PageData) => this.getProviders().forEach(provider => provider.initService(pageData));

  requestHeaderBids(adUnits?: AdUnit[], timeout?: number) {
    this.getProviders().forEach(provider => provider.sendBidsRequest(adUnits, timeout));
  }
}
