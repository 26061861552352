import { domAPI } from 'mm-commercial-utils';
import { logger } from '../utils/logger';
import { DEFAULT_TARGETING_KEYS_MAPPING } from '../headerBidding/prebid/prebidService.constants';
import { AdUnit } from '../adUnits/AdUnit';

declare global {
  interface Window {
    googletag: googletag.Googletag;
    adsbygoogle?: Record<string, unknown>;
  }
}

interface CustomPubAdsService extends googletag.PubAdsService {
  isInitialLoadDisabled: () => boolean;
}

window.googletag = window.googletag || { cmd: [] };

export const googleTagServerName = 'googletag';

export class GoogleTagProvider {
  private scriptUrl = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

  constructor() {
    this.run(() => {
      window.googletag.pubads().setCentering(true);
      window.addEventListener('adPlaceholderRemoved', (event: CustomEventInit) => {
        const slotToDestroy = window.googletag.pubads().getSlots().find(slot => slot.getSlotElementId() === event.detail.id);
        slotToDestroy && this.destroySlots([slotToDestroy]);
      });
    });
  }

  loadScript = () => {
    const script = document.createElement('script');
    script.src = this.scriptUrl;
    script.onload = () => logger.log('gpt script is loaded');
    document.head.appendChild(script);
  };

  run(functionToRun: () => void) {
    window.googletag.cmd.push(functionToRun);
  }

  refresh(slot: googletag.Slot, adUnit: AdUnit) {
    this.run(() => {
      domAPI.runInAnimationFrame(() => {
        this.setKeyValues(slot, this.mapTargetingKeys(slot, DEFAULT_TARGETING_KEYS_MAPPING));
        this.setKeyValues(slot, { slot_position: `${adUnit.index}-${adUnit.refreshCount || 1}` });
        window.googletag.pubads().refresh([slot]);
      });
    });
  }

  destroySlots(slots: googletag.Slot[]) {
    this.run(() => {
      window.googletag.destroySlots(slots);
    });
  }

  listen(event: string, callback: (event: googletag.events.Event) => void) {
    this.run(() => {
      window.googletag.pubads().addEventListener(event, callback);
    });
  }

  display(slot: googletag.Slot, adUnit: AdUnit) {
    this.run(() => {
      domAPI.runInAnimationFrame(() => {
        this.setKeyValues(slot, this.mapTargetingKeys(slot, DEFAULT_TARGETING_KEYS_MAPPING));
        this.setKeyValues(slot, { slot_position: `${adUnit.index}-${adUnit.refreshCount || 1}` });
        if (slot.getAdUnitPath().toLowerCase().includes('top') || (slot.getAdUnitPath().toLowerCase().includes('1x1_m') && domAPI.getSiteName() === '90min.com')) this.setKeyValues(slot, { SkinEnable: 'true' });
        else this.setKeyValues(slot, { SkinEnable: 'false' });
        if ((window as any).commercialIntentIq) this.setKeyValues(slot, { intent_iq_group: (window as any).commercialIntentIq.intentIqConfig?.abTesting?.currentTestGroup || 'U' });
        window.googletag.display(slot);
      });
    });
  }

  refreshOrDisplay(slot: googletag.Slot, adUnit: AdUnit, forceRefresh = false) {
    this.run(() => {
      const shouldBeRefreshed = forceRefresh || (window.googletag.pubads() as CustomPubAdsService).isInitialLoadDisabled();
      return shouldBeRefreshed ? this.refresh(slot, adUnit) : this.display(slot, adUnit);
    });
  }

  createSlot(adUnitHierarchy: string, sizes: number[][], placeholderValue: string) {
    return window.googletag.defineSlot(adUnitHierarchy, sizes || [[300, 250]], placeholderValue)
      .addService(window.googletag.pubads())
      .setCollapseEmptyDiv(true);
  }

  enableServices() {
    this.run(() => {
      window.googletag.enableServices();
    });
  }

  mapTargetingKeys(slot: googletag.Slot, targetingKeysMap: Record<string, string>) {
    return Object.entries(targetingKeysMap)
      .reduce((accumulator, [targetingKey, mappedTargetingKey]) => {
        const value = slot.getTargeting(targetingKey);
        slot.clearTargeting(targetingKey);
        return {
          ...accumulator,
          [mappedTargetingKey]: value,
        };
      }, {});
  }

  setKeyValues(slot: googletag.Slot, keyValues: Record<string, string>) {
    Object.entries(keyValues).forEach(([key, value]) => slot.setTargeting(key, value));
  }
}
