import { AssetType, VideoCampaign, VideoDirectCampaign } from 'campaign-manager';
import { domAPI, getRandomItemFromArray } from 'mm-commercial-utils';
import { EventStreamer } from '../eventStreaming/EventStreamer';

enum PlayerEvent {
  AdImpression = 'adImpression',
  Impression = 'impression',
  Play = 'play',
}

export type Player = {
  setPromotedContent: (ids: string[], videoServingMethod: string) => void;
  on: (eventName: PlayerEvent, callback: (params: { ad: { adId: string; creativeId: string; duration: number } }) => void | (() => void)) => void;
  getCurrentVideoItem: () => { mediaId: string; index: number };
  getPlaylistId: () => string;
  setCallToAction: (buttonString: string, url: string) => void;
  isEmbedded: () => boolean;
}

export type Voltax = { getAllPlayersOnPage: () => Player[] };

declare global {
  interface Window {
    voltax: Voltax;
  }
}

export class VoltaxPlayerService {
  private static instance: VoltaxPlayerService;

  private readonly videoEventsSent: Record<PlayerEvent, boolean>;

  private readonly videoDirectEventsSent: Record<PlayerEvent, string[]>;

  private campaigns: VideoDirectCampaign[];

  private contentIds: string[];

  constructor() {
    this.videoEventsSent = {
      [PlayerEvent.Play]: false,
      [PlayerEvent.Impression]: false,
      [PlayerEvent.AdImpression]: false,
    };
    this.videoDirectEventsSent = {
      [PlayerEvent.Play]: [],
      [PlayerEvent.Impression]: [],
      [PlayerEvent.AdImpression]: [],
    };
    this.campaigns = [];
    this.contentIds = [];
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new VoltaxPlayerService();
    }
    return this.instance;
  }

  private static isAssetPlaying(vplayer: Player, contentId: string) {
    const videoItem = vplayer.getCurrentVideoItem();
    const playlistId = vplayer.getPlaylistId();
    return contentId === videoItem.mediaId || contentId === playlistId;
  }

  private static sortCampaignsByPriority(campaigns: VideoDirectCampaign[]) {
    return campaigns.sort((a, b) => {
      if (a.priority > b.priority) {
        return 1;
      }
      if (a.priority < b.priority) {
        return -1;
      }
      return 0;
    });
  }

  injectAssets(campaigns: { [AssetType.Video]: VideoCampaign | null; [AssetType.VideoDirect]: VideoDirectCampaign[] }) {
    const videoDirectCampaigns = campaigns[AssetType.VideoDirect];
    if (videoDirectCampaigns.length !== 0) {
      this.injectVideoDirect(videoDirectCampaigns);
    }
    const videoCampaign = campaigns[AssetType.Video];
    if (videoCampaign) {
      this.injectVideo(videoCampaign);
    }
  }

  private registerToVideoFirstPlayEvent(vplayer: Player, campaign: VideoCampaign, playerConfigID: string) {
    vplayer.on(PlayerEvent.Play, () => {
      if (!this.videoEventsSent[PlayerEvent.Play]) {
        EventStreamer.sendVideoFirstPlayEvent(campaign, playerConfigID);
        this.videoEventsSent[PlayerEvent.Play] = true;
      }
    });
  }

  private registerToVideoImpressionEvent(vplayer: Player, campaign: VideoCampaign, playerConfigID: string) {
    vplayer.on(PlayerEvent.Impression, () => {
      if (!this.videoEventsSent[PlayerEvent.Impression]) {
        EventStreamer.sendVideoImpressionEvent(campaign, playerConfigID);
        this.videoEventsSent[PlayerEvent.Impression] = true;
      }
    });
  }

  private findCurrentAssetPlayingIndex(vplayer: Player, event: PlayerEvent) {
    return this.contentIds.findIndex((contentId, i) => {
      const campaign = this.campaigns[i];
      return VoltaxPlayerService.isAssetPlaying(vplayer, contentId) && !this.videoDirectEventsSent[event].includes(campaign.id);
    });
  }

  private registerToVideoDirectFirstPlayEvent(vplayer: Player) {
    vplayer.on(PlayerEvent.Play, () => {
      const index = this.findCurrentAssetPlayingIndex(vplayer, PlayerEvent.Play);
      if (index !== -1) {
        const campaign = this.campaigns[index];
        const contentId = this.contentIds[index];
        EventStreamer.sendVideoDirectFirstPlayEvent(campaign, contentId);
        this.videoDirectEventsSent[PlayerEvent.Play].push(campaign.id);
      }
    });
  }

  private registerToVideoDirectImpressionEvent(vplayer: Player) {
    vplayer.on(PlayerEvent.Impression, () => {
      const index = this.findCurrentAssetPlayingIndex(vplayer, PlayerEvent.Impression);
      if (index !== -1) {
        const campaign = this.campaigns[index];
        const contentId = this.contentIds[index];
        const videoItem = vplayer.getCurrentVideoItem();
        EventStreamer.sendVideoDirectImpressionEvent(campaign, contentId, videoItem.index);
        this.videoDirectEventsSent[PlayerEvent.Impression].push(campaign.id);
      }
    });
  }

  private injectVideo(campaign: VideoCampaign) {
    const {
      playerConfigID,
      callToAction,
    } = campaign.asset.payload;
    domAPI.appendScript('https://bucket1.mm-syringe.com/prod/injector/injector.1.5.3.js');
    window.addEventListener('voltaxPlayerLoaded', () => {
      const vplayers = window.voltax.getAllPlayersOnPage();
      const vplayer = vplayers[0];
      EventStreamer.sendVideoPlayerEmbedEvent(campaign, playerConfigID);
      this.registerToVideoFirstPlayEvent(vplayer, campaign, playerConfigID);
      this.registerToVideoImpressionEvent(vplayer, campaign, playerConfigID);
      if (callToAction) {
        vplayer.setCallToAction(callToAction.text, callToAction.url);
      }
    });
    domAPI.appendScript(`${VOLTAX_CONFIG_PREFIX}/${playerConfigID}.js`);
    EventStreamer.sendVideoEmbedEvent(campaign, playerConfigID);
  }

  private injectVideoDirect(campaigns: VideoDirectCampaign[]) {
    this.campaigns = VoltaxPlayerService.sortCampaignsByPriority(campaigns);
    this.contentIds = this.campaigns.map(campaign => getRandomItemFromArray(campaign.asset.payload));
    const onPlayerLoad = () => {
      const vplayers = window.voltax.getAllPlayersOnPage().filter(player => !player.isEmbedded());
      if (vplayers.length !== 0) {
        EventStreamer.sendVideoDirectPlayerEmbedEvent(this.campaigns, this.contentIds);
        const vplayer = vplayers[0];
        this.registerToVideoDirectFirstPlayEvent(vplayer);
        this.registerToVideoDirectImpressionEvent(vplayer);
        vplayer.setPromotedContent(this.contentIds, 'promotionManager');
        EventStreamer.sendVideoDirectEmbedEvent(this.campaigns, this.contentIds);
      }
    };
    if (window.voltax) {
      onPlayerLoad();
    } else {
      window.addEventListener('voltaxPlayerLoaded', onPlayerLoad);
    }
  }
}
