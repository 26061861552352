import { AdUnitsConfigsProvider } from './adUnitsConfigsProvider';

interface AdUnitDataResponse {
  adUnits: {
    templateId: string;
    path: string;
    viewability: number;
  }[]
}

export type AdUnitData = Record<string, { path: string, viewability: string }>

export class AdUnitsApiProvider {
  static async getAdUnitsData(siteName: string) {
    const url = AdUnitsConfigsProvider.adUnitsEndpoint(siteName);
    const configs = {
      method: 'GET',
    };
    const response = await fetch(url, configs);
    const responseData = await response.json();
    Object.assign(AdUnitsApiProvider.adUnitsData, this.formatAdUnitsDataResponse(responseData));
  }

  private static formatAdUnitsDataResponse(adUnitsDataResponse: AdUnitDataResponse) {
    return adUnitsDataResponse.adUnits.reduce((acc, adUnitData) => {
      return {
        ...acc,
        [adUnitData.templateId]: {
          path: adUnitData.path,
          viewability: adUnitData.viewability.toString(),
        },
      };
    }, {});
  }

  static adUnitsData : AdUnitData = {};
}
