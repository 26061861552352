import {
  BidderConfig,
  BidderCreationParams,
  BidderParams,
  BidRequest,
  MediaType,
} from './PrebidService';
import { VideoAdUnit } from '../../adUnits/VideoAdUnit';
import { DEFAULT_FLOOR_PRICE_CONFIGURATION, DEFAULT_FLOOR_PRICE, BIDDERS } from './prebidService.constants';

const BIDDER_SIZE_FORMATTERS: { [p: string]: { formatter: (size: number[]) => string; paramName: string } } = {
  [BIDDERS.PULSEPOINT]: { paramName: 'cf', formatter: (size: number[]) => `${size[0]}X${size[1]}` },
};

function formatBidderSizeParam(bidder: string, { size, ...bidderParams }: BidderParams) {
  const { paramName, formatter } = BIDDER_SIZE_FORMATTERS[bidder];
  return {
    ...{ [paramName]: formatter(size as number[]) },
    ...bidderParams,
  };
}

export class BidderFactory {
  static getBidsForBidder({ bidderConfig, sizes }: BidderCreationParams): BidRequest[] {
    const { bidder, params: paramsBySize } = bidderConfig;
    const sizeStrings = sizes.map(size => size.toString());
    return paramsBySize.reduce((accumulator: BidRequest[], params: BidderParams) => {
      const { size } = params;
      const isMultiSize = typeof size === 'undefined';
      const isCorrectSize = isMultiSize || sizeStrings.includes(size.toString());
      if (isCorrectSize) {
        return [...accumulator, {
          bidder,
          params: BIDDER_SIZE_FORMATTERS[bidder] && !isMultiSize ? formatBidderSizeParam(bidder, params) : params,
        }];
      }
      return accumulator;
    }, []);
  }

  static getFloorPricesConfiguration(biddersConfiguration: Record<MediaType, BidderConfig[]>) {
    const floorValues = Object.entries(biddersConfiguration).reduce((acc, biddersByMediaType) => {
      const [mediaTypeKey, bidder] = biddersByMediaType;
      return {
        ...acc,
        ...BidderFactory.getFloorPricesValues(bidder, mediaTypeKey as MediaType),
      };
    }, {});
    return {
      ...DEFAULT_FLOOR_PRICE_CONFIGURATION,
      data: {
        ...DEFAULT_FLOOR_PRICE_CONFIGURATION.data,
        values: {
          ...DEFAULT_FLOOR_PRICE_CONFIGURATION.data.values,
          ...floorValues,
        },
      },
    };
  }

  static getFloorPricesValues(bidders: BidderConfig[], mediaType: MediaType) {
    return bidders.reduce((acc, bidderConfig) => {
      const { bidder, floorPrice } = bidderConfig;
      return {
        ...acc,
        [`${bidder}|${mediaType}`]: floorPrice || DEFAULT_FLOOR_PRICE[mediaType],
      };
    }, {});
  }

  static getVideoBids(bidders: BidderConfig[], videoAdUnits: VideoAdUnit[]) {
    return videoAdUnits.map(({ code, config: videoAdUnitConfig }) => {
      const videoBids = bidders.reduce((accumulator: BidRequest[], bidderConfig) => {
        const bids = this.getBidsForBidder({ bidderConfig, sizes: [] });
        return bidderConfig ? [...accumulator, ...bids] : accumulator;
      }, []);

      return {
        code,
        sizes: videoAdUnitConfig.playerSize,
        mediaTypes: { video: videoAdUnitConfig },
        bids: videoBids,
      };
    });
  }

  static getBannerBids(bidders: BidderConfig[], sizes: number[][]) {
    const bidsBySize = bidders.reduce((accumulator: BidRequest[], bidderConfig) => {
      const bids = this.getBidsForBidder({ bidderConfig, sizes });
      return bids ? [...accumulator, ...bids] : accumulator;
    }, []);

    return bidsBySize && {
      mediaTypes: {
        banner: { sizes },
      },
      bids: bidsBySize,
    };
  }
}
