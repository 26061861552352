import { AssetType, Campaign, VideoDirectCampaign, AssetPosition, WidgetDirectCampaign } from 'campaign-manager';
import { getRandomItemFromArray, isObject } from 'mm-commercial-utils';

type RawLogomorphPayload = { relativePath: string };
type RawVideoPayload = {
  playerID: string;
  callToAction: { text: string; url: string };
};
type RawVideoDirectPayload = { contentID: string[] };
type RawWidgetDirectPayload = { embed: string };
type RawAssetPayload =
  | RawLogomorphPayload
  | RawVideoPayload
  | RawVideoDirectPayload
  | RawWidgetDirectPayload;
type RawAsset = {
  id: string;
  type: AssetType;
  position?: AssetPosition;
  payload: string | string[] | RawAssetPayload;
};
export type RawCampaign = {
  id: string;
  groupID: string;
  priority: number;
  asset: RawAsset;
};

export class CampaignManagerService {
  private static instance: CampaignManagerService;

  private readonly campaigns: Record<AssetType, Campaign[]>;

  constructor() {
    this.campaigns = {
      [AssetType.Logomorph]: [],
      [AssetType.WidgetDirect]: [],
      [AssetType.Video]: [],
      [AssetType.VideoDirect]: [],
    };
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new CampaignManagerService();
    }
    return this.instance;
  }

  private static getCampaignWithHighestPriority(campaigns: Campaign[]) {
    const priority = Math.min(
      ...campaigns.map(campaign => campaign.priority),
    );
    return getRandomItemFromArray(
      campaigns.filter(campaign => campaign.priority === priority),
    );
  }

  private static formatAsset(asset: RawAsset) {
    switch (asset.type) {
      case AssetType.Logomorph: {
        if (isObject(asset.payload)) {
          const payload = asset.payload as RawLogomorphPayload;
          return {
            id: asset.id,
            type: asset.type,
            payload: payload.relativePath,
          };
        }
        return asset;
      }
      case AssetType.Video: {
        if (isObject(asset.payload)) {
          const payload = asset.payload as RawVideoPayload;
          return {
            id: asset.id,
            type: asset.type,
            payload: {
              playerConfigID: payload.playerID,
              callToAction: payload.callToAction
                ? {
                  text: payload.callToAction.text,
                  url: payload.callToAction.url,
                }
                : null,
            },
          };
        }
        return {
          id: asset.id,
          type: asset.type,
          payload: { playerConfigID: asset.payload },
        };
      }
      case AssetType.VideoDirect: {
        if (isObject(asset.payload)) {
          const payload = asset.payload as RawVideoDirectPayload;
          return {
            id: asset.id,
            type: asset.type,
            payload: payload.contentID,
          };
        }
        return asset;
      }
      case AssetType.WidgetDirect: {
        if (isObject(asset.payload)) {
          const payload = asset.payload as RawWidgetDirectPayload;
          return {
            id: asset.id,
            type: asset.type,
            payload: payload.embed,
            position: asset.position,
          };
        }
        return asset;
      }
      default:
        throw new Error(`${asset.type} is not an asset type`);
    }
  }

  private static formatCampaign(campaign: RawCampaign) {
    return {
      id: campaign.id,
      groupId: campaign.groupID,
      priority: campaign.priority,
      asset: CampaignManagerService.formatAsset(campaign.asset),
    };
  }

  private static getCampaignsByPosition(
    widgetDirectCampaigns: WidgetDirectCampaign[],
  ) {
    const campaignsByPosition = {
      [AssetPosition.Top]: [],
      [AssetPosition.Middle]: [],
      [AssetPosition.Bottom]: [],
    } as Record<AssetPosition, WidgetDirectCampaign[]>;
    widgetDirectCampaigns.forEach(campaign => {
      const assetPosition = campaign.asset.position;
      campaignsByPosition[assetPosition].push(campaign);
    });
    return campaignsByPosition;
  }

  getCampaignByAssetType(assetType: AssetType) {
    const campaigns = this.campaigns[assetType];
    if (campaigns.length === 0) {
      return null;
    }
    if (assetType === AssetType.WidgetDirect) {
      const campaignsByPosition = CampaignManagerService.getCampaignsByPosition(
        campaigns as WidgetDirectCampaign[],
      );
      const widgetCampaigns = Object.values(campaignsByPosition).filter(campaignsWithSamePosition => campaignsWithSamePosition.length !== 0);
      const campaignsWithHighestPriority = widgetCampaigns.reduce((acc: WidgetDirectCampaign[], campaignsWithSamePosition: WidgetDirectCampaign[]) => {
        const campaignWithHighestPriority = CampaignManagerService.getCampaignWithHighestPriority(campaignsWithSamePosition);
        return [
          ...acc,
          campaignWithHighestPriority as WidgetDirectCampaign,
        ];
      }, []);
      return campaignsWithHighestPriority;
    }
    return CampaignManagerService.getCampaignWithHighestPriority(campaigns);
  }

  getVideoDirectCampaigns() {
    return this.campaigns[AssetType.VideoDirect] as VideoDirectCampaign[];
  }

  addCampaign(campaign: RawCampaign) {
    const formattedCampaign = CampaignManagerService.formatCampaign(campaign);
    this.pushCampaignToQueue(formattedCampaign);
  }

  private pushCampaignToQueue(campaign: Campaign) {
    this.campaigns[campaign.asset.type].push(campaign);
  }
}
