import { PageData, CampaignItem } from 'injector';
import { AssetType, VideoCampaign, VideoDirectCampaign } from 'campaign-manager';
import { createUniqueIdentifier } from 'mm-commercial-utils';

enum Event {
    AdImpression = 'adImpression',
    Embed = 'embed',
    FirstPlay = 'firstPlay',
    Impression = 'impression',
    PlayerEmbed = 'playerEmbed',
    Session = 'session',
    SessionEnd = 'session-end'
}

export class EventStreamer {
    static sessionId: string = createUniqueIdentifier();

    static sessionStart = Date.now();

    private static getURLHostnameAndPath() {
      const urlObject = new URL(window.location.href);
      return `${urlObject.hostname}${urlObject.pathname}`;
    }

    private static getURLQueryParameter(param: string) {
      const urlObject = new URL(window.location.href);
      return urlObject.searchParams.get(param) || '';
    }

    private static sendEvent(event: Record<string, unknown>) {
      navigator.sendBeacon(EVENT_STREAMER_ENDPOINT, JSON.stringify(event));
    }

    private static sendVideoEvent(eventType: Event, campaign: VideoCampaign | VideoDirectCampaign, payload: Record<string, string>, customPayload?: { key: string; value: string }[]) {
      EventStreamer.sendEvent({
        type: eventType,
        sessionID: this.sessionId,
        campaignGroupID: campaign.groupId,
        campaignUnitID: campaign.id,
        asset: {
          id: campaign.asset.id,
          type: campaign.asset.type,
          ...payload,
        },
        customKV: customPayload,
      });
    }

    private static sendCampaignEvent(eventType: Event, campaign: CampaignItem, customPayload?: { key: string; value: string }[]) {
      EventStreamer.sendEvent({
        type: eventType,
        sessionID: this.sessionId,
        campaignGroupID: campaign.campaignGroupId,
        campaignUnitID: campaign.campaignId,
        asset: {
          id: campaign.assetId,
          type: campaign.assetType,
        },
        customKV: customPayload,
      });
    }

    private static sendAggregatedVideoEvent(eventType: Event, campaigns: VideoDirectCampaign[], contentIds: string[], customPayload?: { key: string; value: string }[]) {
      EventStreamer.sendEvent({
        type: eventType,
        sessionID: this.sessionId,
        asset: {
          type: AssetType.VideoDirect,
        },
        customKV: [
          ...campaigns.map((campaign, index) => [
            {
              key: 'campaignUnitID',
              value: campaign.id,
            },
            {
              key: 'campaignGroupID',
              value: campaign.groupId,
            },
            {
              key: 'asset.id',
              value: campaign.asset.id,
            },
            {
              key: 'asset.contentID',
              value: contentIds[index],
            },
          ])
            .flat(),
          ...customPayload || [],
        ],
      });
    }

    static sendVideoEmbedEvent(campaign: VideoCampaign, playerConfigID: string) {
      this.sendVideoEvent(Event.Embed, campaign, { playerID: playerConfigID });
    }

    static sendWidgetEmbedEvent() {
      // EventStreamer.sendCampaignEvent(Event.Embed, campaign, customPayload);
    }

    static sendWidgetImpressionEvent() {
      // EventStreamer.sendCampaignEvent(Event.Impression, campaign);
    }

    static sendLogomorphEmbedEvent(campaign: CampaignItem) {
      EventStreamer.sendCampaignEvent(Event.Embed, campaign);
    }

    static sendVideoDirectEmbedEvent(campaigns: VideoDirectCampaign[], contentIds: string[]) {
      this.sendAggregatedVideoEvent(Event.Embed, campaigns, contentIds);
    }

    static sendVideoImpressionEvent(campaign: VideoCampaign, playerConfigID: string) {
      this.sendVideoEvent(Event.Impression, campaign, { playerID: playerConfigID });
    }

    static sendVideoDirectImpressionEvent(campaign: VideoDirectCampaign, contentId: string, videoIndex: number) {
      this.sendVideoEvent(Event.Impression, campaign, { contentID: contentId }, [{
        key: 'videoIndex',
        value: videoIndex.toString(),
      }]);
    }

    static sendVideoPlayerEmbedEvent(campaign: VideoCampaign, playerConfigID: string) {
      this.sendVideoEvent(Event.PlayerEmbed, campaign, { playerID: playerConfigID });
    }

    static sendVideoDirectPlayerEmbedEvent(campaigns: VideoDirectCampaign[], contentIds: string[]) {
      this.sendAggregatedVideoEvent(Event.PlayerEmbed, campaigns, contentIds);
    }

    static sendVideoFirstPlayEvent(campaign: VideoCampaign, playerConfigID: string) {
      this.sendVideoEvent(Event.FirstPlay, campaign, { playerID: playerConfigID });
    }

    static sendVideoDirectFirstPlayEvent(campaign: VideoDirectCampaign, contentId: string) {
      this.sendVideoEvent(Event.FirstPlay, campaign, { contentID: contentId });
    }

    static sendSessionEvent(pageData: PageData) {
      const {
        country,
        state,
        platform,
        operatingSystem,
        trafficSource,
        mmUserIdentifier,
        tags,
        distributionChannels,
        language,
      } = pageData;
      EventStreamer.sendEvent({
        type: Event.Session,
        sessionID: this.sessionId,
        env: {
          url: EventStreamer.getURLHostnameAndPath(),
          source: EventStreamer.getURLQueryParameter('utm_source'),
          medium: EventStreamer.getURLQueryParameter('utm_medium'),
          country,
          state,
          platform,
          os: operatingSystem,
          trafficSource,
          mmUserIdentifier,
          commercialTags: tags,
          distributionChannels,
          language,
        },
      });
    }

    static sendVideoDirectAdImpressionEvent(campaigns: VideoDirectCampaign[], contentIds: string[], adId: string, creativeId: string, duration: number, videoItemIndex: number) {
      this.sendAggregatedVideoEvent(Event.AdImpression, campaigns, contentIds, [
        {
          key: 'adId',
          value: adId,
        },
        {
          key: 'creativeId',
          value: creativeId,
        },
        {
          key: 'duration',
          value: duration.toString(),
        },
        {
          key: 'videoItemIndex',
          value: videoItemIndex.toString(),
        },
      ]);
    }

    static sendVideoDirectSessionEndEvent(campaigns: VideoDirectCampaign[], contentIds: string[]) {
      const duration = Math.floor((Date.now() - this.sessionStart) / 1000);
      this.sendAggregatedVideoEvent(Event.SessionEnd, campaigns, contentIds, [{
        key: 'duration',
        value: duration.toString(),
      }]);
    }
}
