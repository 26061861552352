import { AdsManagerSettings, DEFAULT_ADS_MANAGER_OPTIONS, EVENTS } from '../settings/settings';
import { HeaderBiddingService } from '../headerBidding/HeaderBiddingService';
import { GoogleTagProvider } from '../providers/GoogleTagProvider';
import { logger } from '../utils/logger';
import { AdUnitsRegistry } from '../adUnits/AdUnitsRegistry';
import { AdUnit } from '..';
import { RequestManager } from '../requestManager/RequestManager';
import { PrebidBid, PrebidService } from '../headerBidding/prebid/PrebidService';
import { A9Service } from '../headerBidding/a9/A9Service';
import { VideoAdUnit } from '../adUnits/VideoAdUnit';
import { PREBID_TIMEOUT } from '../headerBidding/prebid/prebidService.constants';

export type PageData = {
  country: string;
  state: string;
  platform: string;
  operatingSystem: string;
  trafficSource: string;
  tags: string[];
  distributionChannels: string[];
  language: string;
  mmUserIdentifier: number;
  experiment: string;
  pageType: string;
  articleId: string;
  trafficSourceAndId: string;
  author: string;
  sessionid: string;
  affiliate?: string;
}

type DefineSlotCallback = (slot: googletag.Slot) => googletag.Slot;

type AdsManagerModule = (adsManager: AdsManager, options?: Record<string, unknown>) => void;

export interface EventSubscription {
    unsubscribe: () => boolean;
}

export interface VideoAuctionAdUnit {
    adUnitId: string;
}

export interface AdsManagerModuleConfig {
    module: AdsManagerModule;
    options?: Record<string, unknown>;
}

type WinningBid = Record<string, Partial<PrebidBid>>;

export class AdsManager {
    private static instance: AdsManager;

    settings: AdsManagerSettings = DEFAULT_ADS_MANAGER_OPTIONS;

    adUnitsRegistry: AdUnitsRegistry;

    winningBids: WinningBid = {};

    readonly googleTagProvider: GoogleTagProvider = new GoogleTagProvider();

    adServerRequestManager: RequestManager = new RequestManager();

    private events: Record<string, Set<(payload?: AdUnit) => void>> = {};

    private headerBiddingProviders: Record<string, HeaderBiddingService> = {};

    private constructor() {
      this.adUnitsRegistry = new AdUnitsRegistry();
    }

    public static getInstance(): AdsManager {
      if (!AdsManager.instance) {
        AdsManager.instance = new AdsManager();
      }
      return AdsManager.instance;
    }

    readonly init = async (modules: AdsManagerModuleConfig[], settings: AdsManagerSettings, pageData: PageData) => {
      this.setSettings(settings);
      this.initGoogleTag();
      await this.initProviders();
      this.initModules(modules);
      this.initBiddingProviders(pageData);
      this.dispatchEvent(EVENTS.ON_INIT);
    };

    readonly setSettings = (settings: Partial<AdsManagerSettings>) => {
      this.settings = {
        ...this.settings,
        ...settings,
      };
    };

    readonly setWinningBids = (winningBids: WinningBid) => {
      this.winningBids = {
        ...this.winningBids,
        ...winningBids,
      };
    };

    subscribe(eventName: string, callback: (payload?: AdUnit) => void) {
      this.events[eventName] = this.events[eventName] || new Set();
      this.events[eventName].add(callback);
      return { unsubscribe: (): boolean => this.events[eventName].delete(callback) };
    }

    renderAdUnit = (adUnit: AdUnit) => {
      const { slot } = adUnit;
      if (slot) {
        this.onAdUnitBeforeRender(adUnit);
        const shouldForceRefresh = adUnit.refreshCount > 0;
        this.googleTagProvider.refreshOrDisplay(slot, adUnit, shouldForceRefresh);
        this.onAdUnitRender(adUnit);
      }
    };

    destroyAdUnit(adUnit: AdUnit) {
      if (adUnit.slot) {
        this.googleTagProvider.destroySlots([adUnit.slot]);
      }
      Object.values(this.headerBiddingProviders).forEach(provider => provider.removeAdUnit(adUnit));
      this.adUnitsRegistry.removeAdUnit(adUnit);
    }

    insertPlacement(placeholder: AdUnit['placeholder'], sizes: number[][], adUnitHierarchy: string, options: Partial<AdUnit>) {
      return this.createAdUnit({
        placeholder,
        sizes,
        adUnitHierarchy,
        shouldInject: false,
        refresh: false,
        refreshInterval: 12000,
        viewableThreshold: 70,
        lazyLoadThreshold: this.settings.lazyLoadThreshold,
        ...options,
      });
    }

    async insertVideo(adUnits: VideoAuctionAdUnit[], timeout: number, bidsBackHandler: (bids: string[]) => void) {
      const videoAdUnits = adUnits.map(adUnitOptions => new VideoAdUnit(adUnitOptions.adUnitId));
      await this.adServerRequestManager.setVideoAdUnits(videoAdUnits, timeout, bidsBackHandler);
    }

    requestAds(adUnits: AdUnit[], timeout = PREBID_TIMEOUT) {
      if (adUnits.length > 0) {
        this.runAuction(adUnits, timeout);
      }
    }

    private onBiddersReady = (adUnits: AdUnit[] = []) => {
      this.googleTagProvider.enableServices();
      this.dispatchEvent(EVENTS.BIDDERS_READY);
      adUnits.forEach(this.renderAdUnit);
    };

    private registerAdUnit = (adUnitId: string) => {
      const adUnit = this.adUnitsRegistry.getAdUnit(adUnitId);
      if (typeof adUnit === 'undefined') {
        return;
      }
      const { isRegistered } = adUnit;

      if (!isRegistered) {
        Object.values(this.headerBiddingProviders).forEach((provider: HeaderBiddingService) => {
          return provider.registerAdUnits([adUnit]);
        });
        this.googleTagProvider.run(() => {
          this.defineSlot(adUnit, slot => {
            adUnit.slot = slot;
            adUnit.isRegistered = true;
            return slot;
          });
        });
        this.dispatchEvent(EVENTS.ON_ADUNIT_REGISTERED, adUnit);
      }
    };

    private readonly initGoogleTag = () => {
      if (this.settings.shouldLoadGPTScript) {
        this.googleTagProvider.loadScript();
      }
    };

    private readonly initBiddingProviders = (pageData: PageData) => {
      if (this.adServerRequestManager) {
        this.adServerRequestManager.initServices(pageData);
      }
    };

    private readonly runAuction = (adUnits: AdUnit[], timeout: number) => {
      adUnits.forEach(adUnit => this.registerAdUnit(adUnit.placeholder));
      if (this.adServerRequestManager) {
        this.adServerRequestManager.requestHeaderBids(adUnits, timeout);
      }
    };

    private initModules = (modules: AdsManagerModuleConfig[]) => {
      modules.forEach(({ module, options }) => module(this, options));
    };

    private initProviders = async () => {
      this.headerBiddingProviders.prebid = new PrebidService({
        adUnits: this.adUnitsRegistry.adUnits,
        config: this.settings.prebidConfig,
        onBidWon: this.onBidWon,
      });
      if (this.settings.a9Config?.banner) {
        this.headerBiddingProviders.a9 = new A9Service({
          ...this.settings.a9Config,
          adUnits: this.adUnitsRegistry.adUnits,
        });
        await A9Service.loadScript();
      }
      this.adServerRequestManager.initProviders(this.headerBiddingProviders, this.onBiddersReady);
    };

    private onAdUnitBeforeRender = (adUnit: AdUnit) => {
      logger.log('before rendering adUnit', adUnit);
      this.dispatchEvent(EVENTS.ON_BEFORE_RENDER, adUnit);
    };

    private onAdUnitRender = (adUnit: AdUnit) => {
      logger.log('rendering adUnit', adUnit);
      adUnit.setProps({ isDisplayed: true });
      this.dispatchEvent(EVENTS.ON_RENDER, adUnit);
    };

    private dispatchEvent(eventName: string, payload?: AdUnit) {
      const events = this.events[eventName];
      if (events) {
        events.forEach(callback => callback(payload));
      }
    }

    private defineSlot(adUnit: AdUnit, callback: DefineSlotCallback) {
      const { placeholder, sizes, adUnitHierarchy } = adUnit;
      this.googleTagProvider.run(() => {
        const slot = this.googleTagProvider.createSlot(adUnitHierarchy, sizes || [[300, 250]], placeholder);
        callback(slot);
      });
    }

    private createAdUnit = (options: Partial<AdUnit> = {}): AdUnit => this.adUnitsRegistry.addAdUnit(options);

    private onBidWon = (data: PrebidBid) => {
      const adUnit = this.adUnitsRegistry.getAdUnit(data.adUnitCode);
      if (!adUnit) {
        return;
      }
      this.winningBids[adUnit.placeholder] = data;
      const { bidderCode, adUnitCode, cpm } = data;
      logger.table('Winning Bid', { bidderCode, adUnitCode, cpm, data });
    };
}
