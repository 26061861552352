// TODO: Should be a provider?
import { logger } from './logger';

type CmpApiCommand = 'addEventListener' | 'removeEventListener' | 'getConsentData';
type CmpApiCallback = ((tcData: TcfData, success: boolean) => void) | ((success: boolean) => void);
type CmpApi = (command: CmpApiCommand, version: number, callback: CmpApiCallback, parameter?: number | number[]) => void;

declare global {
  interface Window {
    __cmp: CmpApi | undefined;
    __tcfapi: CmpApi | undefined;
  }
}

export interface TcfData {
  gdprApplies: boolean;
  tcString: string;
  listenerId: string;
}

export const subscribeToTcf2 = (callback: (data: TcfData | null) => void) => {
  // eslint-disable-next-line no-underscore-dangle
  if (window.__tcfapi) {
    // eslint-disable-next-line no-underscore-dangle
    const tcfCallback = (tcData: TcfData, success: boolean) => {
      // eslint-disable-next-line no-underscore-dangle,no-unused-expressions
      window.__tcfapi && window.__tcfapi('removeEventListener', 2, (removed: boolean) => {
        if (removed) {
          logger.log('Stop listenning to', tcData.listenerId);
        }
      }, parseInt(tcData.listenerId, 10));
      logger.log('TCF2 consent', tcData);
      if (success) {
        callback(tcData);
      } else {
        callback(null);
      }
    };
    // eslint-disable-next-line no-underscore-dangle
    window.__tcfapi('addEventListener', 2, tcfCallback, [1, 2, 3]);
  } else {
    logger.log('The Site is not compliant with TCF2');
    callback(null);
  }
};

export const subscribeToCmp = (callback: (data: TcfData | null) => void) => {
  // eslint-disable-next-line no-underscore-dangle
  if (window.__cmp) {
    // eslint-disable-next-line no-underscore-dangle
    window.__cmp('getConsentData', 2, (data: TcfData, success: boolean) => {
      if (success) {
        callback(data);
      } else {
        callback(null);
      }
    });
  } else {
    callback(null);
  }
};

export const getTcfData = (callback: (data: TcfData | null) => void) => {
  subscribeToTcf2(callback);
};

export const isValidConsent = (data: TcfData | null) => {
  if (data === null) {
    return true;
  }
  const { gdprApplies, tcString } = data;
  const noGdprAndConsent = !gdprApplies && !tcString;
  return gdprApplies || noGdprAndConsent;
};
