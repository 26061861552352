type VideoAdContext = 'instream' | 'outstream';

export interface VideoAdUnitConfig {
    context: VideoAdContext;
    placement: number;
    playerSize: number[]|number[][];
    mimes: string[];
    protocols: number[];
    api: number[];
    linearity: number;
    maxduration?: number;
    minduration?: number;
    playbackmethod?: number[];
    skip?: number;
}
export const DEFAULT_VIDEO_AUCTION_TIMEOUT = 1500;
export const DEFAULT_VIDEO_AD_UNIT_CONFIG: VideoAdUnitConfig = {
  context: 'instream',
  placement: 1,
  playerSize: [640, 480],
  mimes: ['video/mp4', 'video/webm', 'application/javascript'],
  protocols: [1, 2, 3, 4, 5, 6],
  api: [2],
  linearity: 1,
  maxduration: 30,
  minduration: 6,
  playbackmethod: [2],
  skip: 1,
};

export class VideoAdUnit {
    code: string;

    config: VideoAdUnitConfig = DEFAULT_VIDEO_AD_UNIT_CONFIG;

    constructor(code: string, settings?: VideoAdUnitConfig) {
      this.code = code;
      this.config = { ...this.config, ...settings };
    }
}
