import {
  Injector,
  NonPlatformStrategy,
  PageData,
  PlatformStrategy,
  CampaignItem,
  PredefinedAssetsByPosition,
} from 'injector';
import {
  AssetType,
  LogomorphCampaign,
  WidgetDirectCampaign,
} from 'campaign-manager';
import { isUndefined } from 'mm-commercial-utils';
import { NO_ADS_TAG, NO_CAMPAIGN_MANAGER } from 'injector/src/Injector';
import { EventStreamer } from '../eventStreaming/EventStreamer';
import { DynamicAssets, SitePolicy, StaticAssets } from './sitePolicy.service';
import {
  convertLogomorphCampaignToInjectorItem,
  convertSitePolicyDynamicAssetsToInjectorConfig,
  convertSitePolicyStaticAssetsToInjectorConfig,
  convertWidgetCampaignToPredefinedDynamicAsset,
} from '../formatters/formatters';
import { AdsManagerService } from './adsManager.service';

function onDynamicAssetsReady(dynamicAssets: DynamicAssets) {
  return async (placeholders: string[]) => {
    if (dynamicAssets.adUnits.length > 0) {
      await AdsManagerService.getInstance().populateDynamicPlaceholders(
        placeholders,
        dynamicAssets,
      );
    }
  };
}

type DynamicInjectorData = {
  enableAdsOnFirstContent: boolean,
  dynamicAssets: DynamicAssets,
  widgetCampaigns?: WidgetDirectCampaign[],
  widgetEmbedEventCallback: (campaign: CampaignItem) => void,
  widgetImpressionEventCallback: (campaign: CampaignItem) => void,
  sitePolicyId: string,
  pageData: PageData,
}

export class InjectorService {
  private static instance: InjectorService;

  static getInstance() {
    if (!this.instance) {
      this.instance = new InjectorService();
    }
    return this.instance;
  }

  readonly injector: Injector;

  placeholders: string[];

  constructor() {
    this.injector = new Injector(
      INJECTOR_STRATEGY === 'platform'
        ? new PlatformStrategy()
        : new NonPlatformStrategy(),
    );
    this.placeholders = [];
  }

  getDynamicInjectorConfig(dynamicInjectorData: DynamicInjectorData) {
    const {
      enableAdsOnFirstContent,
      dynamicAssets,
      widgetCampaigns,
      widgetEmbedEventCallback,
      widgetImpressionEventCallback,
    } = dynamicInjectorData;

    const dynamicInjectorConfig = convertSitePolicyDynamicAssetsToInjectorConfig(
      dynamicAssets,
      enableAdsOnFirstContent,
    );
    if (widgetCampaigns) {
      dynamicInjectorConfig.predefinedAssets = widgetCampaigns.reduce((acc: PredefinedAssetsByPosition, widgetCampaign: WidgetDirectCampaign) => {
        const predefinedDynamicAsset = convertWidgetCampaignToPredefinedDynamicAsset(widgetCampaign);
        const { position } = widgetCampaign.asset;
        acc[position] = predefinedDynamicAsset;
        return acc;
      }, {});
    }
    return {
      dynamicInjectorConfig,
      widgetEmbedEventCallback,
      widgetImpressionEventCallback,
    };
  }

  getStaticInjectorConfig(staticAssets: StaticAssets) {
    return convertSitePolicyStaticAssetsToInjectorConfig(
      staticAssets,
      AdsManagerService.getInstance().populateStaticPlaceholder,
    );
  }

  async injectAssets(
    sitePolicy: SitePolicy,
    campaigns: {
      [AssetType.WidgetDirect]: WidgetDirectCampaign[];
      [AssetType.Logomorph]: LogomorphCampaign;
    },
    pageData: PageData,
  ) {
    const { enableWidgets, enableAdsOnFirstContent, assets } = sitePolicy;
    const { dynamic: dynamicAssets, static: staticAssets } = assets;
    const { maxAssets } = dynamicAssets;
    const widgetCampaigns = !pageData.tags.includes(NO_CAMPAIGN_MANAGER) && enableWidgets
      ? campaigns[AssetType.WidgetDirect]
      : undefined;
    this.injector.setShouldEnableAds(!pageData.tags.includes(NO_ADS_TAG) && enableAdsOnFirstContent);

    if (!pageData.tags.includes(NO_ADS_TAG) && sitePolicy.enableAds) {
      await AdsManagerService.getInstance().initWithSitePolicyAndPageData(
        sitePolicy,
        pageData,
      );
      this.injectStaticAssets(staticAssets);
    }
    if (maxAssets > 0 || widgetCampaigns) {
      await this.injectDynamicAssets(
        { enableAdsOnFirstContent,
          dynamicAssets: assets.dynamic,
          widgetCampaigns,
          widgetEmbedEventCallback: EventStreamer.sendWidgetEmbedEvent,
          widgetImpressionEventCallback: EventStreamer.sendWidgetImpressionEvent,
          sitePolicyId: sitePolicy.id,
          pageData,
        },
      );
    }
    if (campaigns[AssetType.Logomorph]) {
      this.injector.injectVideoLogo(
        !isUndefined(window.mmClientApi),
        convertLogomorphCampaignToInjectorItem(
          campaigns[AssetType.Logomorph],
        ),
        EventStreamer.sendLogomorphEmbedEvent,
      );
    }
  }

  getPageData() {
    return this.injector.getPageData(!isUndefined(window.mmClientApi));
  }

  private injectStaticAssets(staticAssets: StaticAssets) {
    this.injector.injectStatic(this.getStaticInjectorConfig(staticAssets));
  }

  private async injectDynamicAssets(dynamicInjectorData: DynamicInjectorData) {
    const
      { dynamicAssets, pageData, sitePolicyId } = dynamicInjectorData;
    const { dynamicInjectorConfig, widgetEmbedEventCallback, widgetImpressionEventCallback } = this.getDynamicInjectorConfig(dynamicInjectorData);
    const onDynamicAssetsReadyCallback = onDynamicAssetsReady(dynamicAssets);
    await this.injector.injectDynamic(
      dynamicInjectorConfig,
      widgetEmbedEventCallback,
      widgetImpressionEventCallback,
      pageData,
      sitePolicyId,
      onDynamicAssetsReadyCallback,
    );
  }
}
