import { domAPI } from 'mm-commercial-utils';
import { DynamicPlaceholder } from './DynamicPlaceholder';
import { StaticPlaceholder } from './StaticPlaceholder';
import { InjectorItem } from '../../../Injector';

const DOM_OBSERVER_OPTIONS = {
  attributes: false,
  childList: true,
  subtree: true,
};

export enum PageContentEvent {
  DynamicPlaceholderFound = 'dynamicPlaceholderFound',
  StaticPlaceholderFound = 'staticPlaceholderFound',
}

export type CONTENT_FOUND_EVENT_CALLBACK = (contentFound: DynamicPlaceholder | StaticPlaceholder) => void;

export class PageContentManager {
  dynamicPlaceholders = new Map<Node, DynamicPlaceholder>();

  staticPlaceholders = new Map<Node, StaticPlaceholder>();

  rootCssSelector: string;

  staticAssets: InjectorItem[];

  observer: MutationObserver;

  subscriptions: Record<PageContentEvent, Set<CONTENT_FOUND_EVENT_CALLBACK>> = {
    dynamicPlaceholderFound: new Set(),
    staticPlaceholderFound: new Set(),
  };

  constructor() {
    this.rootCssSelector = 'body';
    this.staticAssets = [];
    this.observer = this.initializeMutationObserver();
  }

  initDynamicPlaceholders(rootCssSelector: string) {
    this.rootCssSelector = rootCssSelector;
  }

  initStaticAssets(staticAssets: InjectorItem[]) {
    staticAssets.forEach(asset => {
      const { selector } = asset.payload;
      if (selector) {
        this.staticAssets.push(asset);
      }
    });
  }

  public findAndSetDynamicPlaceholders(mutationList?: MutationRecord[]) {
    if (this.rootCssSelector) {
      const rootElements = domAPI.querySelectorAll(document.documentElement, this.rootCssSelector);
      if (rootElements.length > 0) {
        rootElements.forEach(rootElement => this.setDynamicPlaceholder(rootElement, mutationList));
      }
    }
  }

  public findAndSetStaticPlaceholders() {
    this.staticAssets.forEach(asset => {
      const { selector } = asset.payload;
      if (selector) {
        const staticAssetElements = domAPI.querySelectorAll(document.documentElement, selector);
        if (staticAssetElements.length > 0) {
          staticAssetElements.forEach(staticAssetsElement => this.setStaticPlaceholder(staticAssetsElement, asset));
        }
      }
    });
  }

  subscribe(eventName: PageContentEvent, callback: (contentInstance: DynamicPlaceholder | StaticPlaceholder) => void) {
    this.subscriptions[eventName].add(callback);
    return () => {
      this.subscriptions[eventName].delete(callback);
    };
  }

  private initializeMutationObserver() {
    const observer = new MutationObserver(mutationList => this.findAndSetContentAssets(mutationList));
    observer.observe(document.body, DOM_OBSERVER_OPTIONS);
    return observer;
  }

  private findAndSetContentAssets(mutationList: MutationRecord[]) {
    this.findAndSetStaticPlaceholders();
    this.findAndSetDynamicPlaceholders(mutationList);
  }

  private setStaticPlaceholder(staticPlaceholderElement: Element, asset: InjectorItem) {
    if (!this.staticPlaceholders.has(staticPlaceholderElement)) {
      const staticPlaceholder = new StaticPlaceholder(staticPlaceholderElement, this.staticPlaceholders.size + 1, asset);
      this.staticPlaceholders.set(staticPlaceholderElement, staticPlaceholder);
      this.triggerContentFoundEvent(PageContentEvent.StaticPlaceholderFound, staticPlaceholder);
    }
  }

  private createDynamicPlaceholder(rootElement: Element) {
    const newDynamicPlaceholder = new DynamicPlaceholder(rootElement, this.dynamicPlaceholders.size + 1);
    this.dynamicPlaceholders.set(rootElement, newDynamicPlaceholder);
    this.triggerContentFoundEvent(PageContentEvent.DynamicPlaceholderFound, newDynamicPlaceholder);
  }

  private updateDynamicPlaceholder(dynamicPlaceholder: DynamicPlaceholder, mutationList: MutationRecord[]) {
    const dynamicPlaceholderMutationRecord = mutationList.find(({ target }) => dynamicPlaceholder.node === target || dynamicPlaceholder.node.contains(target));
    if (!dynamicPlaceholderMutationRecord) {
      return;
    }
    const { removedNodes, addedNodes } = dynamicPlaceholderMutationRecord;
    this.observer.disconnect();
    dynamicPlaceholder.updatePlaceholders(removedNodes, addedNodes, (updated: boolean) => {
      if (updated) {
        this.triggerContentFoundEvent(PageContentEvent.DynamicPlaceholderFound, dynamicPlaceholder);
      }
    });
    this.observer.observe(document.body, DOM_OBSERVER_OPTIONS);
  }

  private setDynamicPlaceholder(rootElement: Element, mutationList?: MutationRecord[]) {
    const dynamicPlaceholder = this.dynamicPlaceholders.get(rootElement);
    if (dynamicPlaceholder && mutationList) {
      return this.updateDynamicPlaceholder(dynamicPlaceholder, mutationList);
    }
    return this.createDynamicPlaceholder(rootElement);
  }

  private getEventSubscriptions(eventName: PageContentEvent) {
    return Array.from(this.subscriptions[eventName].values());
  }

  private triggerContentFoundEvent(eventName: PageContentEvent, payload: StaticPlaceholder | DynamicPlaceholder) {
    this.getEventSubscriptions(eventName)
      .forEach((subscription => subscription(payload)));
  }
}
