import { PrebidServiceConfig, BidRequest, SchemaField } from './PrebidService';

export const PREBID_TIMEOUT = 2500;

export const TBT_HOSTNAME = 'theplayerstribune.com';

const PREBID_PRICE_BUCKET_KEY = 'hb_pb';
export const DEFAULT_TARGETING_KEYS_MAPPING = {
  [PREBID_PRICE_BUCKET_KEY]: 'mm_hb_pb',
};

export const SIZES = {
  MOBILE: [[300, 250]],
  DESKTOP: [[300, 250], [728, 90], [468, 60]],
};

const CUSTOM_GRANULARITY_VIDEO = {
  buckets: [
    { precision: 2, max: 5, increment: 0.05 },
    { precision: 2, max: 30, increment: 0.10 },
  ],
};
const CUSTOM_GRANULARITY_BANNER = {
  buckets: [
    { precision: 2, max: 20, increment: 0.01 },
  ],
};

export const BIDDERS = {
  IX: 'ix',
  SOVRN: 'sovrn',
  TRIPLELIFT: 'triplelift',
  YIELDMO: 'yieldmo',
  RHYTHMONE: 'rhythmone',
  PULSEPOINT: 'pulsepoint',
  RUBICON: 'rubicon',
  OPENX: 'openx',
  APPNEXUS: 'appnexus',
  TEADS: 'teads',
  APPMONET: 'amx',
  JUSTPREMIUM: 'justpremium',
  ONEMOBILE: 'onemobile',
  THIRTYTHREEACROSS: '33across',
  KARGO: 'kargo',
  KUEEZRTB: 'kueezrtb',
  OFTMEDIA: 'oftmedia',
  INSTICATOR: 'insticator',
  INVIBES: 'invibes',
  BIGHAPPY: 'bighappy',
  OGURY: 'ogury',
  MINUTEMEDIA: 'minutemedia',
  NEXTMILLENNIUM: 'nextMillenium',
  YAHOOSSP: 'yahoossp',
  GRID: 'grid',
  SEEDTAG: 'seedtag',
  VIDAZOO: 'vidazoo',
  COLOSSUS: 'colossusssp',
};

export const BIDDER_ADAPTERS_MAP = {
  [BIDDERS.OFTMEDIA]: BIDDERS.APPNEXUS,
  [BIDDERS.BIGHAPPY]: BIDDERS.APPNEXUS,
  [BIDDERS.ONEMOBILE]: BIDDERS.YAHOOSSP,
  [BIDDERS.KUEEZRTB]: 'kueezRtb',
};

export const OPENX_ANALYTICS_OPTIONS = {
  publisherPlatformId: 'e09631e7-ef6f-4d45-9b58-1700280e02e3',
  publisherAccountId: 540780517,
  sampling: 1,
};

export const FLOORS_MODEL_VERSION = 'MinuteMedia Ad Unit Floors 1.0';
export const DEFAULT_FLOOR_PRICE = {
  video: 1.5,
  banner: 0.1,
};
export const DEFAULT_FLOOR_PRICE_CONFIGURATION = {
  enforcement: {
    floorDeals: false,
  },
  floorMin: 0.01,
  data: {
    floorProvider: '',
    skipRate: 5,
    currency: 'USD',
    schema: {
      delimiter: '|',
      fields: ['bidder', 'mediaType'] as SchemaField[],
    },
    modelVersion: FLOORS_MODEL_VERSION,
    values: {},
  },
  additionalSchemaFields: {
    bidder: (bidRequest: BidRequest) => bidRequest.bidder,
  },
};

export const DEFAULT_PREBID_CONFIG: PrebidServiceConfig = {
  pageUrl: `${window.location.origin}${window.location.pathname}`,
  bidders: {
    video: [],
    banner: [],
  },
  useBidCache: true,
  mediaTypePriceGranularity: {
    video: CUSTOM_GRANULARITY_VIDEO,
    banner: CUSTOM_GRANULARITY_BANNER,
  },
  enableSendAllBids: true,
  targetingControls: {
    alwaysIncludeDeals: true,
    allowTargetingKeys: ['BIDDER', 'AD_ID', 'PRICE_BUCKET', 'SIZE', 'DEAL', 'SOURCE', 'FORMAT', 'UUID', 'CACHE_ID', 'CACHE_HOST'],
  },
  biddersConfig: {
    oftmedia: {
      bidCpmAdjustment(bidCpm) {
        return bidCpm > 0 ? bidCpm * 0.90 : bidCpm;
      },
    },
  },
  cache: {
    url: 'https://prebid.adnxs.com/pbc/v1/cache',
  },
  consentManagement: {
    gdpr: {
      cmpApi: 'iab',
      timeout: 10000,
      defaultGdprScope: true,
      allowAuctionWithoutConsent: true,
      rules: [],
    },
  },
  mass: {
    renderUrl: 'https://cdn.massplatform.net/bootloader.js',
  },
  floors: DEFAULT_FLOOR_PRICE_CONFIGURATION,
  userSync: {
    userIds: [
      {
        name: 'uid2',
      },
      {
        name: 'sharedId',
        storage: {
          name: '_sharedID',
          type: 'cookie',
          expires: 30,
        },
      },
      {
        name: 'identityLink',
        params: {
          pid: '13815',
          notUse3P: false,
        },
        storage: {
          type: 'html5',
          name: 'idl_env',
          expires: 15,
          refreshInSeconds: 1800,
        },
      },
    ],
    syncDelay: 3000,
  },
};

export const mediaTypes = {
  banner: {
    sizes: [[300, 250], [320, 50], [300, 50]],
  },
  oneOnOne: {
    sizes: [[1, 1]],
  },
};

export const hadronUserId = {
  name: 'hadronId',
  params: {
    partnerId: 454,
  },
  storage: {
    type: 'html5',
    name: 'hadronId',
  },
};

export const realTimeData = {
  auctionDelay: 500,
  dataProviders: [
    {
      name: 'hadron',
      waitForIt: true,
      params: {
        segmentCache: false,
        partnerId: 454,
      },
    },
  ],
};
