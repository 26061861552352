import { trackingModule } from '../tracking/trackingModule';
import { defaultTrackingMethod } from '../tracking/tracking.utils';
import { coreLoader } from '../loaders/coreLoader';
import { refresh, RefreshOptions } from '../refresh/refresh';
import { DfpKeyValuesOptions, targetingKeyValues } from '../targetingKeyValues/targetingKeyValues';
import { queryParams } from '../queryParams/queryParams';
import { AdsManagerModuleConfig } from '../../adsManager/AdsManager';

export interface AdsManagerModuleOptions {
  dfpKeyValuesSettings: DfpKeyValuesOptions;
  refreshSettings: RefreshOptions;
}

export const genericPreset = ({
  dfpKeyValuesSettings,
  refreshSettings,
}: Partial<AdsManagerModuleOptions>): AdsManagerModuleConfig[] => [
  {
    module: trackingModule,
    options: {
      trackingMethods: [defaultTrackingMethod],
    },
  },
  { module: queryParams },
  {
    module: targetingKeyValues,
    options: { ...dfpKeyValuesSettings },
  },
  {
    module: coreLoader,
  },
  {
    module: refresh,
    options: {
      runAuctionOnRefresh: true,
      enableForAll: false,
      ...refreshSettings,
    },
  },
];
