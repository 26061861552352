export type AdUnitOptions = Partial<AdUnit>;
export type StickyPositions = 'bottom' | 'top';

interface StickyConfig {
    position: StickyPositions;
}

export const DEFAULT_VIEWABILITY = 0.7;

export const DEFUALT_LAZY_LOAD_THRESHOLD = 1.2;

export function formatViewabilityTargetingValue(viewability: number) {
  return viewability.toFixed(2);
}

export const VIEWABILITY_KEY = 'mm_viewability';

export interface DfpKeyValues {
    [VIEWABILITY_KEY]: string;
}

export type Sticky = StickyConfig | null | undefined;

export class AdUnit {
    stickyWrapper: HTMLElement | null = null;

    isVisible = false;

    index = 0;

    adUnitId = 0;

    isRegistered = false;

    isDisplayed = false;

    shouldInject = true;

    triggerAuction = true;

    slot: googletag.Slot | null = null;

    refresh = false;

    refreshInterval = 12000;

    viewableThreshold = 70;

    refreshLimit = 0;

    lazyLoadThreshold = DEFUALT_LAZY_LOAD_THRESHOLD;

    dfpKeyValues: DfpKeyValues = {
      [VIEWABILITY_KEY]: formatViewabilityTargetingValue(DEFAULT_VIEWABILITY),
    };

    private refreshCountValue = 0;

    private placeholderValue = '';

    private inViewPercentageAverage = 0;

    private adUnitHierarchyValue = '';

    private dataIdValue = '';

    private sizesValue: number[][] = [[300, 250]];

    private intersectionObserver: IntersectionObserver | null = null;

    private stickyValue: Sticky = null;

    constructor(options: AdUnitOptions) {
      this.setProps(options);
    }

    set refreshCount(refreshCount: number) {
      this.refreshCountValue = refreshCount;
    }

    get refreshCount() {
      return this.refreshCountValue;
    }

    get sizes(): number[][] {
      return this.sizesValue;
    }

    set sizes(sizes: number[][]) {
      this.sizesValue = sizes;
    }

    get placeholder() {
      return this.placeholderValue;
    }

    set placeholder(placeholder: string) {
      this.placeholderValue = placeholder;
    }

    get adUnitHierarchy(): string {
      return this.adUnitHierarchyValue;
    }

    set adUnitHierarchy(adUnitHierarchy: string) {
      this.adUnitHierarchyValue = adUnitHierarchy;
    }

    get sticky(): Sticky {
      return this.stickyValue;
    }

    set sticky(sticky: Sticky) {
      this.stickyValue = sticky;
      if (this.stickyValue) {
        this.observeSticky();
      }
    }

    set inViewPercentage(inViewPercentage: number) {
      const maxInViewPercentage = Math.max(this.inViewPercentageAverage, inViewPercentage);
      this.inViewPercentageAverage = maxInViewPercentage;
      const currentViewability = (this.dfpKeyValues?.mm_viewability ? parseFloat(this.dfpKeyValues.mm_viewability) : DEFAULT_VIEWABILITY) * 100;
      const averageViewability = (currentViewability + maxInViewPercentage) / 2;
      const flooredAverageViewability = Math.floor(averageViewability / 5) * 5;
      this.setProps({
        dfpKeyValues: {
          ...this.dfpKeyValues,
          [VIEWABILITY_KEY]: formatViewabilityTargetingValue(Math.max(currentViewability, flooredAverageViewability) / 100),
        },
      });
    }

    get dataId(): string {
      return this.dataIdValue;
    }

    set dataId(dataId: string) {
      this.dataIdValue = dataId;
    }

    get adUnitElementRef(): HTMLElement | null {
      return document.getElementById(this.placeholder);
    }

    get isOneOnOne() {
      return this.sizes.length === 1 && this.sizes[0][0] === 1 && this.sizes[0][0] === this.sizes[0][1];
    }

    private static createStickyWrapper(adUnitElementRef: HTMLElement) {
      const wrapper = document.createElement('div');
      wrapper.style.display = 'flex';
      wrapper.style.justifyContent = 'center';
      adUnitElementRef.before(wrapper);
      wrapper.appendChild(adUnitElementRef);
      return wrapper;
    }

    setProps(props: AdUnitOptions) {
      Object.assign(this, { ...props });
    }

    setRectangleDimensions(boundingClientRect: ClientRect) {
      if (this.adUnitElementRef) {
        const { width, height } = boundingClientRect;
        this.adUnitElementRef.style.width = `${width}px`;
        this.adUnitElementRef.style.minHeight = `${height}px`;
      }
    }

    private toggleSticky(shouldToggleSticky: boolean) {
      if (this.adUnitElementRef && this.sticky) {
        if (shouldToggleSticky) {
          this.adUnitElementRef.style.position = 'fixed';
          this.adUnitElementRef.style.zIndex = '1000000001';
        } else {
          this.adUnitElementRef.style.position = 'relative';
          this.adUnitElementRef.style.zIndex = '1';
        }
      }
    }

    private setStickyStyles() {
      if (this.adUnitElementRef && this.sticky) {
        const { position } = this.sticky;
        if (position === 'bottom') {
          this.adUnitElementRef.style.bottom = '0';
        }
        if (position === 'top') {
          this.adUnitElementRef.style.top = '0';
        }
        if (this.stickyWrapper) {
          this.adUnitElementRef.style.width = `${this.stickyWrapper?.offsetWidth}px`;
        }
        this.adUnitElementRef.style.zIndex = '1000000001';
        this.adUnitElementRef.style.backgroundColor = 'white';
        this.adUnitElementRef.style.marginLeft = 'auto';
        this.adUnitElementRef.style.marginRight = 'auto';
        this.adUnitElementRef.style.flex = '1';
      }
    }

    private observeSticky() {
      if (this.adUnitElementRef && this.sticky) {
        const { position } = this.sticky;
        this.stickyWrapper = AdUnit.createStickyWrapper(this.adUnitElementRef);
        this.setStickyStyles();
        this.toggleSticky(true);
        this.intersectionObserver = new IntersectionObserver(entries => {
          return entries.forEach(entry => {
            const { boundingClientRect } = entry;
            const shouldToggleStickyTop = position === 'top' && entry.intersectionRatio < 1 && boundingClientRect.top < 0;
            const shouldToggleStickyBottom = position === 'bottom' && entry.intersectionRatio < 1 && (window.innerHeight - boundingClientRect.bottom) < 0;
            if (shouldToggleStickyTop || shouldToggleStickyBottom) {
              this.toggleSticky(true);
            } else {
              this.toggleSticky(false);
            }
          });
        }, {
          root: null,
          rootMargin: '0px 0px 0px 0px',
          threshold: [0, 0.25, 0.5, 0.75, 1],
        });
        this.intersectionObserver.observe(this.stickyWrapper);
      }
    }
}
