import { domAPI } from 'mm-commercial-utils';
import {
  CampaignItem,
  InjectorItem,
  PageData,
  PredefinedAssetsByPosition,
} from '../../../Injector';
import {
  createPlaceholderHTML,
  getDynamicAssetPlaceholderId,
  getPositionIndexForAsset,
  getValidPredefinedAssetsList,
} from '../../strategies.utils';
import {
  ALLOWED_TAG_NAMES,
  isNotEmptyContentBlock,
  isNotInsideTwitter,
  isValidImage,
  mapHTMLElementToContentBlock,
  updateDynamicPositionState,
  getWidgetCampaignsItems, isNotInsideSemanticElement,
} from './dynamicPlaceholder.utils';
import { BLOCK_TYPES } from '../../strategies.constants';
import { WidgetIntersectionObserver } from './WidgetIntersectionObserver';
import { predefinedAssetsManager } from './PredefinedAssetManager';

type DynamicPositionsPolicy = {
  firstAdPosition: number;
  blocksBetweenAds: number;
  predefinedAssets?: PredefinedAssetsByPosition;
};

export class DynamicPlaceholder {
  node: Element;

  index: number;

  private placeholderElements: Map<string, HTMLElement>;

  constructor(rootElement: Element, index: number) {
    this.placeholderElements = new Map();
    this.node = rootElement;
    this.index = index;
  }

  get placeholders() {
    return Array.from(this.placeholderElements.keys());
  }

  get contentBlocks() {
    return this.buildContentBlocks();
  }

  findDynamicPositions(dynamicPositionsPolicy: DynamicPositionsPolicy) {
    const { firstAdPosition, blocksBetweenAds } = dynamicPositionsPolicy;
    const contentBlocksLength = this.contentBlocks.length;
    const initialDynamicPositionState = {
      skipCorrection: 0,
      charsBetween: 0,
      floatStart: 0,
      positions: [] as number[],
      firstAdPosition,
      blocksBetweenAds,
      contentBlocksLength,
      index: 0,
      lastFoundPositionIndex: 0,
      lastFoundPositionTop: 0,
    };

    const { positions } = this.contentBlocks.reduce((accumulator, block, index, sourceArray) => {
      const previousBlock = sourceArray[index - 1];
      const nextBlock = sourceArray[index + 1];
      return updateDynamicPositionState({
        ...accumulator,
        index,
        block,
        previousBlock,
        nextBlock,
      });
    },
    initialDynamicPositionState);
    return positions;
  }

  setPlaceholders(assets: InjectorItem[], positions: number[], maxAssets: number) {
    positions.slice(0, maxAssets)
      .forEach((position, index) => {
        const insertionOptions = this.createInsertionOptions(position);
        const currentAsset = assets[index % assets.length];
        let html = '';
        if (currentAsset) {
          html = currentAsset.payload.html;
        }

        const verticalMargin = domAPI.getComputedStyles('p').lineHeight;
        const placeholderId = getDynamicAssetPlaceholderId(position, this.index);
        const placeholderHTML = createPlaceholderHTML(placeholderId, html, verticalMargin);
        domAPI.insertHTMLFromString(insertionOptions.element, insertionOptions.position, placeholderHTML);
        this.placeholderElements.set(placeholderId, domAPI.getElementById(placeholderId) as HTMLElement);
      });
  }

  populatePredefinedAssetsPositions(
    availablePositions: number[],
    predefinedWidgetAssets: PredefinedAssetsByPosition,
    widgetEmbedEventCallback: (campaign: CampaignItem, customPayload?: { [key: string]: string }[]) => void,
    widgetImpressionEventCallback: (campaign: CampaignItem) => void,
    pageData: PageData,
    sitePolicyId: string,
  ) {
    const predefinedAssetsList = getValidPredefinedAssetsList(
      predefinedWidgetAssets,
      availablePositions.length,
    );
    const CampaignsItems = getWidgetCampaignsItems(predefinedAssetsList);
    const widgetIntersectionObserver = new WidgetIntersectionObserver(predefinedAssetsList.length);
    widgetIntersectionObserver.initWidgetIntersectionObserver(widgetImpressionEventCallback, CampaignsItems);
    let dynamicAssetsPositions = [...availablePositions];
    predefinedAssetsList.forEach(asset => {
      const { item, campaignId } = asset;
      const predefinedAssetItem = predefinedAssetsManager.get(campaignId) || {
        placeholderElementRef: null,
        scriptElementRef: null,
        isScriptInjected: false,
        isScriptLoaded: false,
        isItemShown: false,
        interactionState: 'idle',
      };
      if (predefinedAssetItem.isScriptLoaded) {
        return;
      }
      const { payload, assetPosition } = item;
      const positionIndex = getPositionIndexForAsset(
        assetPosition,
        availablePositions,
        predefinedAssetsList,
      );
      const positionForAsset = availablePositions[positionIndex];
      if (!payload.script || !predefinedAssetItem.isScriptLoaded) {
        const widgetPlaceholderHTML = createPlaceholderHTML(campaignId, payload.html);
        const { element, position } = this.createInsertionOptions(positionForAsset);
        domAPI.insertHTMLFromString(element, position, widgetPlaceholderHTML);
        const widgetPlaceholder = domAPI.getElementById(campaignId);
        if (widgetPlaceholder) {
          predefinedAssetItem.placeholderElementRef = widgetPlaceholder;
          widgetEmbedEventCallback(CampaignsItems[campaignId], [{ key: 'policyId', value: sitePolicyId }, { key: 'experimentId', value: pageData.experiment }]);
          widgetIntersectionObserver.observeWidget(widgetPlaceholder);
        }
        if (payload.script) {
          if (predefinedAssetItem.scriptElementRef === null) {
            predefinedAssetItem.scriptElementRef = domAPI.appendScript(payload.script, true, () => {
              predefinedAssetItem.isScriptLoaded = true;
            });
          }
        }
        dynamicAssetsPositions = dynamicAssetsPositions.filter(dynamicAssetPosition => dynamicAssetPosition !== positionForAsset);
        predefinedAssetsManager.set(campaignId, predefinedAssetItem);
      }
    });
    return dynamicAssetsPositions;
  }

  shouldUpdate = (removedNodes: NodeList, addedNodes: NodeList) => {
    const allowedAddedNodes = [
      ...Array.from(addedNodes),
    ].filter(block => ALLOWED_TAG_NAMES.includes((block as HTMLElement).tagName) || (block as HTMLElement).innerHTML?.includes('<picture'));
    const allowedRemovedNodes = [
      ...Array.from(removedNodes),
    ].filter(block => ALLOWED_TAG_NAMES.includes((block as HTMLElement).tagName) || (block as HTMLElement).innerHTML?.includes('<picture'));
    const addedContentBlocks = allowedAddedNodes && allowedAddedNodes.length > 0;
    const removedContentBlocks = allowedRemovedNodes && allowedRemovedNodes.length > 0;

    return removedContentBlocks || addedContentBlocks || this.contentBlocks.some(block => block.elementRef.clientHeight === 0);
  };

  updatePlaceholders(removedNodes: NodeList, addedNodes: NodeList, callback: (updated: boolean) => void) {
    if (this.shouldUpdate(removedNodes, addedNodes)) {
      Array.from(this.placeholderElements.values()).forEach(placeholderElement => {
        domAPI.removeAdPlaceholder(placeholderElement);
      });
      predefinedAssetsManager.forEach(predefinedAssetItem => {
        const { placeholderElementRef, scriptElementRef } = predefinedAssetItem;
        placeholderElementRef && domAPI.removeAdPlaceholder(placeholderElementRef);
        scriptElementRef && domAPI.removeAdPlaceholder(scriptElementRef);
      });
      callback(true);
    }
    callback(false);
  }

  private createInsertionOptions(position: number): { element: HTMLElement; position: InsertPosition; } {
    return this.contentBlocks[position - 1]
      ? { element: this.contentBlocks[position - 1].elementRef, position: 'afterend' }
      : { element: this.contentBlocks[position].elementRef, position: 'beforebegin' };
  }

  private createContentBlocksFromHtmlElements(blocks: HTMLElement[]) {
    return blocks
      .map((contentBlock, index) => mapHTMLElementToContentBlock(contentBlock as HTMLElement, index))
      .filter(
        contentBlock => contentBlock.height > 0
          && isNotEmptyContentBlock(contentBlock)
          && isNotInsideTwitter(contentBlock)
          && isNotInsideSemanticElement(contentBlock)
          && (contentBlock.blockType !== BLOCK_TYPES.IMAGE
            || isValidImage(contentBlock)),
      );
  }

  private buildContentBlocks() {
    const blocks = domAPI.querySelectorAll(
      this.node,
      ALLOWED_TAG_NAMES.join(','),
    );
    return this.createContentBlocksFromHtmlElements(blocks as HTMLElement[]);
  }
}
