import device, { DeviceOs } from 'current-device-information';

type ExtendedDeviceOs = 'macos' | DeviceOs;

export {
  clientAPI,
  InjectAdPlaceholdersObject,
  InjectArticleBlockByIndexObject,
  PageDataObject,
} from './providers/clientAPI';
export { domAPI } from './providers/domAPI';

export const PLATFORMS = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
} as const;

export const OPERATING_SYSTEMS = {
  MACOS: 'macos',
  WINDOWS: 'windows',
  ANDROID: 'android',
  IOS: 'ios',
  OTHER: 'other',
} as const;

export const TRAFFIC_SOURCES = {
  UNKNOWN: 'unknown',
  INFLUENCER: 'influencer',
  APP: 'app',
  EMBED_PARTNER: 'embed_partner',
  SYNDICATION: 'syndication',
  PAID: 'paid',
  SOCIAL: 'social',
  PARTNER: 'tr_partner',
  ORGANIC: 'organic',
  NAVIGATION: 'navigation',
  RSS: 'rss',
  SHARE: 'share',
  EMAIL: 'email',
  REFERRAL: 'referral',
  DIRECT: 'direct',
} as const;

type UserGeo = { country: string; state: string }
const userGeo = {} as UserGeo;

export function getCookieValue(name: string) {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(name))
    ?.split('=')[1];
}

export function getUserPlatform() {
  switch (device.type) {
    case 'desktop':
      return PLATFORMS.DESKTOP;
    case 'mobile':
      return PLATFORMS.MOBILE;
    case 'tablet':
      return PLATFORMS.TABLET;
    default:
      return PLATFORMS.MOBILE;
  }
}

export function getOperatingSystem() {
  switch (device.os as ExtendedDeviceOs) {
    case 'android':
      return OPERATING_SYSTEMS.ANDROID;
    case 'ios':
    case 'ipad':
    case 'iphone':
    case 'ipod':
      return OPERATING_SYSTEMS.IOS;
    case 'windows':
      return OPERATING_SYSTEMS.WINDOWS;
    case 'macos':
      return OPERATING_SYSTEMS.MACOS;
    default:
      return OPERATING_SYSTEMS.OTHER;
  }
}

export function createUniqueIdentifier() {
  let base = Date.now();
  if (typeof window !== 'undefined' && window.performance) {
    base += window.performance.now();
  }
  return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, () => {
    /* eslint-disable-next-line no-bitwise */
    const r = (base + Math.random() * 10) % 10 | 0;
    base = Math.floor(base / 10);
    return r.toString(10);
  });
}

export function isChrome() {
  return typeof navigator !== 'undefined' && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
}

export function isEmptyObject(object: Record<string, unknown>) {
  return Object.keys(object).length === 0;
}

export async function getUserGeo() {
  if (isEmptyObject(userGeo)) {
    const response = await fetch('https://cdn.mmctsvc.com/.mc/geo');
    const { geo } = await response.json();
    userGeo.country = geo.country;
    userGeo.state = geo.state;
  }
  return userGeo;
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === 'undefined';
}

export function isObject(value: unknown) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function getRandomItemFromArray<Type>(array: Type[]) {
  return array[Math.floor(Math.random() * array.length)];
}
