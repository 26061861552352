import { CampaignItem } from '../../../Injector';

export class WidgetIntersectionObserver {
  numberOfInjectedWidgets: number;

  private widgetIntersectionObserver: IntersectionObserver | null = null;

  constructor(numInjectedWidget: number) {
    this.numberOfInjectedWidgets = numInjectedWidget;
  }

  observeWidget(widgetPlaceholder: HTMLElement): void {
    if (this.widgetIntersectionObserver) {
      this.widgetIntersectionObserver.observe(widgetPlaceholder);
    }
  }

  initWidgetIntersectionObserver(widgetImpressionEventCallback: (campaign: CampaignItem) => void, CampaignsItems: Record<string, CampaignItem>) {
    const observer = new IntersectionObserver(entries => {
      return entries.forEach(entry => {
        if (entry.isIntersecting) {
          widgetImpressionEventCallback(CampaignsItems[entry.target.id]);
          this.numberOfInjectedWidgets -= 1;
          if (this.numberOfInjectedWidgets === 0) {
            observer.disconnect();
          } else {
            observer.unobserve(entry.target);
          }
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.5, 0.75, 1],
    });
    this.widgetIntersectionObserver = observer;
  }
}
