import { EVENTS } from '../../settings/settings';
import { AdsManager } from '../../adsManager/AdsManager';
import {
  AdUnit,
  formatViewabilityTargetingValue,
  VIEWABILITY_KEY,
} from '../../adUnits/AdUnit';

export interface DfpKeyValuesOptions {
    queryParams?: [string, string][];
    customParams?: Record<string, string | undefined>;
}

// TODO: change query params property to be an object
export const DEFAULT_KEY_VALUES_OPTIONS: DfpKeyValuesOptions = {
  queryParams: [
    ['utm_campaign', 'key'],
  ],
  customParams: {},
};

export const targetingKeyValues = (adsManager: AdsManager, settings = {}) => {
  const options = {
    ...DEFAULT_KEY_VALUES_OPTIONS,
    ...settings,
  };
  adsManager.subscribe(EVENTS.ON_BEFORE_RENDER, (adUnit: AdUnit | undefined) => {
    if (adUnit && adUnit.slot) {
      const { slot } = adUnit;
      const { queryParams } = adsManager.settings;
      const { dfpKeyValues: keyValuesFromSettings } = adUnit;
      const adUnitDfpKeyValues = {
        ...keyValuesFromSettings,
        [VIEWABILITY_KEY]: formatViewabilityTargetingValue(parseFloat(keyValuesFromSettings[VIEWABILITY_KEY])),
      };
      const keyValuesFromParams = options.queryParams?.reduce((accumulator, [paramKey, dfpKey]) => {
        return queryParams ? {
          ...accumulator,
          [dfpKey]: queryParams.get(paramKey),
        } : accumulator;
      }, {});
      const keyValuesToSet = {
        ...keyValuesFromParams,
        ...options.customParams,
        ...adUnitDfpKeyValues,
        slotid: slot.getAdUnitPath(),
      };
      adsManager.googleTagProvider.setKeyValues(slot, keyValuesToSet);
    }
  });
};
