export enum AssetType {
    Logomorph = 'logomorph',
    WidgetDirect = 'widget-direct',
    Video = 'video',
    VideoDirect = 'video-direct',
}

export enum AssetPosition {
    Top = 'top',
    Middle = 'middle',
    Bottom = 'bottom',
}

export type Asset = {
    id: string;
    type: AssetType;
    payload: Record<string, unknown> | string | string[];
}

export type Campaign = {
    id: string;
    asset: Asset;
    priority: number;
    groupId: string;
}

export interface LogomorphCampaign extends Campaign {
    asset: LogomorphAsset;
}

export interface WidgetDirectCampaign extends Campaign {
    asset: WidgetDirectAsset;
}

export interface VideoCampaign extends Campaign {
    asset: VideoAsset;
}

export interface VideoDirectCampaign extends Campaign {
    asset: VideoDirectAsset;
}

interface LogomorphAsset extends Asset {
    payload: string;
}

interface WidgetDirectAsset extends Asset {
    payload: string;
    position: AssetPosition;
}

interface VideoAsset extends Asset {
    payload: {
        playerConfigID: string;
        callToAction?: {
            text: string;
            url: string;
        };
    };
}

interface VideoDirectAsset extends Asset {
    payload: string[];
}
