import {
  clientAPI,
  createUniqueIdentifier,
  getOperatingSystem,
  getUserGeo,
  getUserPlatform,
  PageDataObject,
} from 'mm-commercial-utils';
import { InjectorStrategy } from '../strategies';
import { CampaignItem, DynamicAssetsInjectorConfig, PageData, StaticAssetsInjectorConfig, VideoLogoInjectorItem } from '../../Injector';
import { StaticPlaceholder } from './pageContent/StaticPlaceholder';
import { DynamicPlaceholder } from './pageContent/DynamicPlaceholder';
import { PageContentEvent, PageContentManager } from './pageContent/PageContentManager';

const CDN_ENDPOINT = 'https://images.mmctsvc.com/video/upload';

declare global {
    interface Window {
        __fs_dfp_targeting: {
            articleId: string;
            contentTags: string[];
            pageType: string;
        };
    }
}

export class NonPlatformStrategy implements InjectorStrategy {
    private static readonly fansidedSiteDataObjectKey = '__fs_dfp_targeting';

    private shouldEnableAds = true;

    private pageContentManager = new PageContentManager();

    private delayedUntilAdsEnabled: (() => void)[] = [];

    private static getMetaContent(metaName: string) {
      const element = document.querySelector(`meta[name="${metaName}"]`) as HTMLMetaElement;
      return element?.content.split(',')
        .map(s => s.trim()) || [];
    }

    private static getDistributionChannels() {
      return [
        NonPlatformStrategy.getMetaContent('keywords'),
        NonPlatformStrategy.getMetaContent('tags'),
      ].flat();
    }

    private static getAuthor() {
      const element = document.querySelector('meta[content*="/"][content*=author]') as HTMLMetaElement;
      if (element) {
        const contentArray = element.content.split('/');
        return contentArray[contentArray.length - 2];
      }
      return '';
    }

    private static getAuthorFromDistributionChannels(distributionChannels: string[]) {
      let author = '';
      if (Array.isArray(distributionChannels)) {
        distributionChannels.forEach(element => {
          if (element.startsWith('author')) [, author] = element.split('|');
        });
      }
      return author;
    }

    private static getAffiliate() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const affiliateId = urlParams.get('a_aid');
      return affiliateId;
    }

    private static getArticleId() {
      return window[NonPlatformStrategy.fansidedSiteDataObjectKey]?.articleId || '';
    }

    private static getTags() {
      return window[NonPlatformStrategy.fansidedSiteDataObjectKey]?.contentTags || [];
    }

    private static getPageType() {
      return window[NonPlatformStrategy.fansidedSiteDataObjectKey]?.pageType || '';
    }

    private static getUserIdentifier() {
      let id = window.localStorage.getItem('mmUserIdentifier');
      if (!id) {
        id = createUniqueIdentifier();
        window.localStorage.setItem('mmUserIdentifier', id);
      }
      return id;
    }

    getCookie(name: string): string {
      const cookies = document.cookie.split(';');
      let value = '';
      cookies.forEach(cookie => {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
          value = decodeURIComponent(cookieValue);
        }
      });
      return value;
    }

    async injectDynamic(dynamicAssetsConfig: DynamicAssetsInjectorConfig, widgetEmbedEventCallback: (campaign: CampaignItem) => void,
      widgetImpressionEventCallback: (campaign: CampaignItem) => void, pageData: PageData, sitePolicyId: string, onDynamicAssetsReadyCallback: (placeholders: string[]) => void) {
      if (!dynamicAssetsConfig.rootCssSelector) {
        this.shouldEnableAds = true;
        return;
      }
      this.pageContentManager.initDynamicPlaceholders(dynamicAssetsConfig.rootCssSelector);
      this.pageContentManager.subscribe(PageContentEvent.DynamicPlaceholderFound, dynamicPlaceholder => {
        if (!this.shouldEnableAds) {
          this.shouldEnableAds = true;
          return;
        }
        const {
          firstAdPosition,
          blocksBetweenAds,
          maxAssets,
          assets,
          predefinedAssets,
        } = dynamicAssetsConfig;
        let positions = (dynamicPlaceholder as DynamicPlaceholder).findDynamicPositions({ firstAdPosition, blocksBetweenAds });
        if (predefinedAssets) {
          positions = (dynamicPlaceholder as DynamicPlaceholder).populatePredefinedAssetsPositions([...positions], predefinedAssets, widgetEmbedEventCallback, widgetImpressionEventCallback, pageData, sitePolicyId);
        }
        (dynamicPlaceholder as DynamicPlaceholder).setPlaceholders(assets, positions, maxAssets);
        const { placeholders } = dynamicPlaceholder as DynamicPlaceholder;
        if (placeholders.length > 0 && onDynamicAssetsReadyCallback) {
          onDynamicAssetsReadyCallback(placeholders);
        }
        this.delayedUntilAdsEnabled.forEach(delayedCall => delayedCall());
      });
      this.pageContentManager.findAndSetDynamicPlaceholders();
    }

    injectStatic(staticAssetsInjectorConfig: StaticAssetsInjectorConfig) {
      const { assets } = staticAssetsInjectorConfig;
      this.pageContentManager.subscribe(PageContentEvent.StaticPlaceholderFound, staticPlaceholder => {
        const { asset } = staticPlaceholder as StaticPlaceholder;
        const placeholderId = (staticPlaceholder as StaticPlaceholder).setPlaceholder();
        if (asset.callback && placeholderId) {
          asset.callback(placeholderId);
        }
      });
      if (!this.shouldEnableAds) {
        this.delayedUntilAdsEnabled.push(() => this.pageContentManager.initStaticAssets(assets));
      } else {
        this.pageContentManager.initStaticAssets(assets);
      }
    }

    injectVideoLogo(videoLogoInjectorItem: VideoLogoInjectorItem, logomorphEmbedEventCallback: (logomorphItem: CampaignItem) => void, isPlatform: boolean) {
      const {
        payload,
        assetType,
        assetId,
        campaignGroupId,
        campaignId,
      } = videoLogoInjectorItem;
      if (!payload) {
        return;
      }
      const logomorphCallbackItem = {
        assetType,
        assetId,
        campaignGroupId,
        campaignId,
      };
      if (isPlatform) {
        clientAPI.injectVideoLogo({ videoPath: `${CDN_ENDPOINT}/${payload}` });
        clientAPI.setLogomorphEmbedEvent(logomorphCallbackItem, logomorphEmbedEventCallback);
      }
    }

    // todo: implemented all fields (should some be optional?)
    async getPageData(isPlatform: boolean) {
      const voltaxData = isPlatform ? await clientAPI.getPageDataAsync() : {} as PageDataObject;
      const author = isPlatform ? NonPlatformStrategy.getAuthorFromDistributionChannels(voltaxData.distributionChannels) : NonPlatformStrategy.getAuthor();
      const userGeo = await getUserGeo();
      return Promise.resolve({
        country: voltaxData.countryCode || userGeo.country,
        state: voltaxData.usState || userGeo.state,
        platform: voltaxData.platform || getUserPlatform(),
        operatingSystem: getOperatingSystem(),
        trafficSource: voltaxData.trafficSource || '',
        tags: voltaxData.tags || NonPlatformStrategy.getTags(),
        distributionChannels: voltaxData.distributionChannels || NonPlatformStrategy.getDistributionChannels(),
        language: voltaxData.language || '',
        mmUserIdentifier: voltaxData.mmUserIdentifier
          ? parseInt(voltaxData.mmUserIdentifier.slice(-2), 10)
          : parseInt(NonPlatformStrategy.getUserIdentifier().slice(-2), 10),
        experiment: voltaxData.experiments ? voltaxData.experiments[0] || '' : '',
        pageType: voltaxData.pageType || NonPlatformStrategy.getPageType(),
        articleId: voltaxData.articleId || NonPlatformStrategy.getArticleId(),
        trafficSourceAndId: voltaxData.trafficSourceAndId || '',
        author,
        sessionid: voltaxData.sessionId || this.getCookie('mm-session-id') || this.getCookie('mmSessionId') || 'none',
        affiliate: voltaxData.affiliateId || NonPlatformStrategy.getAffiliate() || '',
      });
    }

    setShouldEnableAds(enableAdsOnFirstContent: boolean) {
      this.shouldEnableAds = enableAdsOnFirstContent;
    }
}
