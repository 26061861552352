import { defaultTrackingMethod, TrackingMethod } from '../modules/tracking/tracking.utils';
import { PrebidServiceConfig } from '..';
import { DEFAULT_PREBID_CONFIG } from '../headerBidding/prebid/prebidService.constants';
import { A9ServiceConfig } from '../headerBidding/a9/A9Service';
import { DEFUALT_LAZY_LOAD_THRESHOLD } from '../adUnits/AdUnit';

const DEFAULT_A_ACCOUNT_ID = '3777';

export interface AdsManagerSettings {
  prebidConfig: PrebidServiceConfig;
  a9Config: A9ServiceConfig | null;
  shouldLoadGPTScript: boolean;
  trackingMethods: TrackingMethod[];
  trackingDelay: number;
  queryParams?: URLSearchParams;
  lazyLoadThreshold: number;
}

export const DEFAULT_ADS_MANAGER_OPTIONS: AdsManagerSettings = {
  prebidConfig: DEFAULT_PREBID_CONFIG,
  lazyLoadThreshold: DEFUALT_LAZY_LOAD_THRESHOLD,
  shouldLoadGPTScript: false,
  trackingMethods: [
    defaultTrackingMethod,
  ],
  a9Config: {
    video: { accountID: DEFAULT_A_ACCOUNT_ID },
    banner: { accountID: DEFAULT_A_ACCOUNT_ID },
  },
  trackingDelay: 3000,
};

export const EVENTS = {
  ON_BEFORE_RENDER: 'onBeforeRender',
  ON_RENDER: 'onRender',
  ON_INIT: 'onInit',
  ON_ADUNIT_REGISTERED: 'onAdunitRegistered',
  BIDDERS_READY: 'biddersReady',
};
