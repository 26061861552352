import { HeaderBiddingService } from '../HeaderBiddingService';
import { logger } from '../../utils/logger';
import { googleTagServerName } from '../../providers/GoogleTagProvider';
import { FAILSAFE_TIMEOUT } from '../headerBiddingService.constants';
import { getPlatformSizes } from '../headerBiddingService.utils';
import { AdUnit } from '../../adUnits/AdUnit';

export interface ApsTag {
  init: (config: { pubID: string; adServer: string }) => void;
  adserverRequestSent: boolean;
  fetchBids: (slots: { slots: AdUnit[] | Record<string, string | number[][]>[] }, callback: (bids: Record<string, unknown>[]) => void) => void;
  setDisplayBids: () => void;
  queue: any;
}

export interface A9ServiceConfig {
  video: { accountID: string };
  banner: { accountID: string };
}

export interface A9ServiceSettings extends A9ServiceConfig {
  adUnits?: AdUnit[];
}

declare global {
  interface Window {
    apstag: ApsTag;
  }
}

export class A9Service implements HeaderBiddingService {
  private pubID: string;

  adUnits: { slotID: string, slotName: string, sizes: number[][]}[];

  biddersBack = false;

  scriptTag = document.createElement('script');

  biddersBackHandler = (serviceName?: string, adUnits?: AdUnit[]) => logger.log(serviceName || '', adUnits);

  constructor(settings: A9ServiceSettings) {
    const { adUnits = [], banner } = settings;
    this.pubID = banner.accountID;
    this.adUnits = this.createAdUnits(adUnits);
    this.registerAdUnits(adUnits);
  }

  private createAdUnit = (adUnit: AdUnit) => ({
    slotID: adUnit.placeholder,
    slotName: adUnit.adUnitHierarchy,
    sizes: adUnit.sizes || getPlatformSizes(),
  });

  private createAdUnits = (adUnits: AdUnit[]) => {
    return adUnits.map(this.createAdUnit);
  };

  registerAdUnits = (adUnits?: AdUnit[]) => {
    const newUnits = adUnits ? this.createAdUnits(adUnits) : this.adUnits;
    this.adUnits = [...this.adUnits, ...newUnits];
  };

  removeAdUnit = (adUnitToRemove: AdUnit) => {
    this.adUnits = this.adUnits.filter(adUnit => adUnit.slotID !== adUnitToRemove.placeholder);
  };

  static scriptLoaded = false;

  static loadScript() {
    try {
      return new Promise(resolve => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = resolve;
        script.src = 'https://c.aps.amazon-adsystem.com/apstag.js';
        script.async = true;
        document.head.appendChild(script);

        window.apstag = window.apstag || {
          queue: [],
          init(...args) { window.apstag.queue.push(['i', args, new Date().getTime()]); },
          fetchBids(...args) { window.apstag.queue.push(['f', args, new Date().getTime()]); },
          setDisplayBids() { /* Empty function */ },
          targetingKeys() { return []; },
        };
      });
    } catch (e: any) {
      logger.error('Error loading a9 script', [e]);
      return null;
    }
  }

  sendBidsRequest(adUnits?: AdUnit[]) {
    if (!window.apstag.init) {
      return;
    }
    window.apstag.adserverRequestSent = false;
    this.biddersBack = false;
    const slots = adUnits ? [...adUnits.map(this.createAdUnit)] : this.adUnits;
    logger.table('sending bids request with slots', slots);
    window.apstag.fetchBids(
      { slots },
      (bids: Record<string, unknown>[]) => {
        logger.table('APS bidders back with bids', bids);
        this.biddersBack = true;
        this.sendAdServerRequest(adUnits);
      },
    );
  }

  sendAdServerRequest(adUnits?: AdUnit[]) {
    if (!window.apstag.adserverRequestSent) {
      window.apstag.adserverRequestSent = true;
      window.apstag.setDisplayBids();
      this.biddersBackHandler('APS', adUnits);
    }
  }

  setProps(props: Partial<HeaderBiddingService>) {
    Object.assign(this, props);
  }

  initService() {
    logger.log('APS init');
    window.apstag.init({
      pubID: this.pubID,
      adServer: googleTagServerName,
    });
    this.sendBidsRequest();
    setTimeout(() => {
      this.sendAdServerRequest();
    }, FAILSAFE_TIMEOUT);
  }
}
