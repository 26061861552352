type WidgetInteractionState = 'idle' | 'interacting' | 'visited';

type PredefinedAseetItem = {
  placeholderElementRef?: HTMLElement | null;
  scriptElementRef?: HTMLScriptElement | null;
  isScriptInjected: boolean;
  isScriptLoaded: boolean;
  isItemShown: boolean;
  interactionState: WidgetInteractionState;
}

export const predefinedAssetsManager: Map<string, PredefinedAseetItem> = new Map();
