function insertHTMLFromString(element: HTMLElement, position: InsertPosition, html: string) {
  return element.insertAdjacentHTML(position, html);
}

function appendScript(src: string, inline = false, onLoad?: (e: Event) => void) {
  const script = document.createElement('script');
  script.async = true;
  if (inline) {
    script.innerText = src.replace(/\n+|\r+/g, '');
  } else {
    script.src = src;
  }

  if (onLoad) {
    const quedCallback = setTimeout((e: Event) => {
      onLoad(e);
      clearTimeout(quedCallback);
    }, 0);
    script.onload = onLoad;
  }

  document.head.appendChild(script);
  return script;
}

function getViewportHeight() {
  return window.innerHeight;
}

function runInAnimationFrame(functionToRun: FrameRequestCallback) {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(functionToRun);
  }
}

function querySelectorAll(node: Element, cssSelector: string) {
  return Array.from(node.querySelectorAll(cssSelector));
}

function getSiteName() {
  return window.location.hostname.replace('www.', '');
}

function getElementById(id: string) {
  return document.getElementById(id);
}

function getComputedStyles(cssSelector: string) {
  const element = document.querySelector(cssSelector) || document.body;
  return window.getComputedStyle(element);
}

function removeAdPlaceholder(placeholderElement: HTMLElement) {
  const adRemoveEvent = new CustomEvent('adPlaceholderRemoved', { detail: placeholderElement });
  window.dispatchEvent(adRemoveEvent);
  placeholderElement.remove();
}

function onDOMReady(callback: () => void) {
  const onReadyStateComplete = () => {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      const nextTick = setTimeout(() => {
        callback();
        clearTimeout(nextTick);
      }, 0);
    } else {
      document.addEventListener('readystatechange', onReadyStateComplete, { once: true });
    }
  };
  onReadyStateComplete();
}

export const domAPI = {
  insertHTMLFromString,
  appendScript,
  getViewportHeight,
  runInAnimationFrame,
  querySelectorAll,
  getSiteName,
  getElementById,
  getComputedStyles,
  removeAdPlaceholder,
  onDOMReady,
};
