import { domAPI } from 'mm-commercial-utils';
import { AdUnit } from './AdUnit';

export class AdUnitsRegistry {
  adUnits: AdUnit[] = [];

  mapToProps: (keyof AdUnit)[] = ['adUnitId', 'placeholder', 'dataId', 'adUnitHierarchy'];

  private adUnitsMap: Map<AdUnit[keyof AdUnit], AdUnit> = new Map();

  private adUnitsIntersectionObserver: IntersectionObserver | null = null;

  get uniquePlaceholders(): AdUnit[] {
    return this.getFromMapUniqueByProp(this.mapToProps[1]);
  }

  get nonDisplayed(): AdUnit[] {
    return this.uniquePlaceholders.filter(adUnit => !adUnit.isDisplayed);
  }

  get size(): number {
    return this.adUnits.length;
  }

  removeAdUnit(adUnit: AdUnit) {
    this.adUnitsMap.delete(adUnit.adUnitId);
  }

  updateAdUnitInViewPercentage(inViewPercentage: number, adUnitHierarchy: string) {
    const currentAverageViewability = this.getAverageAdUnitViewability(inViewPercentage, adUnitHierarchy);
    this.getByAdUnitHierarchy(adUnitHierarchy).forEach(adUnit => {
      adUnit.setProps({ inViewPercentage: currentAverageViewability });
    });
  }

  initAdUnitsIntersectionObserver(onIntersection: IntersectionObserverCallback, intersectionMargin: number) {
    const viewportHeight = domAPI.getViewportHeight();
    const margin = viewportHeight * intersectionMargin;
    this.adUnitsIntersectionObserver = new IntersectionObserver(onIntersection, {
      root: null,
      rootMargin: `${margin}px 0px ${margin}px 0px`,
      threshold: [0, 0.1, 0.25, 0.5, 0.75, 1],
    });
  }

  addAdUnit(options: Partial<AdUnit>) {
    const adUnit = new AdUnit({ adUnitId: this.getDynamicUnitId(), index: this.getNextAdUnitId(), ...options });
    this.mapToProps.forEach(prop => this.adUnitsMap.set(adUnit[prop], adUnit));
    if (options.adUnitHierarchy && !this.adUnitByPropValue('adUnitHierarchy', options.adUnitHierarchy)) {
      this.adUnits.push(adUnit);
    }
    this.observeAdUnit(adUnit);
    return adUnit;
  }

  getAdUnit(adUnitId: number | string) {
    return this.adUnitsMap.get(adUnitId);
  }

  observeAdUnit(adUnit: AdUnit): void {
    if (this.adUnitsIntersectionObserver && adUnit.adUnitElementRef) {
      this.adUnitsIntersectionObserver.observe(adUnit.adUnitElementRef);
    }
  }

  private getFromMapUniqueByProp = (propName: keyof AdUnit) => {
    const uniqueArray: AdUnit[] = [];
    const mapEntries = this.adUnitsMap.entries();

    function run<T>({ value, done }: IteratorResult<T>) {
      if (!done) {
        const [adUnitKey, adUnit] = value;
        if (adUnit[propName] === adUnitKey) {
          uniqueArray.push(value[1]);
        }
        run(mapEntries.next());
      }
    }

    run(mapEntries.next());
    return uniqueArray;
  };

  private getByAdUnitHierarchy(adUnitHierarchy: string) {
    return this.uniquePlaceholders.filter(adUnit => adUnit.adUnitHierarchy === adUnitHierarchy);
  }

  private getAverageAdUnitViewability(inViewPercentage: number, adUnitHierarchy: string) {
    return this.getByAdUnitHierarchy(adUnitHierarchy).reduce((accumulator, adUnit) => {
      const currentViewability = Math.max(adUnit.dfpKeyValues?.mm_viewability ? parseFloat(adUnit.dfpKeyValues.mm_viewability) : 0, inViewPercentage);
      return (accumulator + currentViewability) / 2;
    }, inViewPercentage);
  }

  private getDynamicUnitId() {
    return this.adUnits.filter(adUnit => adUnit.shouldInject).length + 1;
  }

  private getNextAdUnitId() {
    return this.size + 1;
  }

  private adUnitByPropValue<K extends keyof AdUnit, V extends AdUnit[K]>(propName: K, propValue: V) {
    return this.adUnits.find((adUnit: AdUnit) => adUnit[propName] === propValue);
  }
}
