import { logger } from '../../utils/logger';
import { AdUnit } from '../../adUnits/AdUnit';
import { AdsManager } from '../../adsManager/AdsManager';

export interface RefreshOptions {
    viewableThreshold: number;
    refreshInterval: number;
    runAuctionOnRefresh: boolean;
    enableForAll: boolean;
    refreshLimit: number;
}

const DEFAULT_OPTIONS: RefreshOptions = {
  viewableThreshold: 80,
  refreshInterval: 30000,
  runAuctionOnRefresh: true,
  enableForAll: false,
  refreshLimit: 2,
};

const REFRESH_KEY = 'browsiRefresh';
const REFRESH_KEY_VALUE = 'true';

interface VisibilityLog {
    startedAt: number;
    isVisible: boolean;
    visibleFor: number;
    refreshCount: number;
}

const DEFAULT_LOG: VisibilityLog = { startedAt: 0, isVisible: false, visibleFor: 0, refreshCount: 0 };

export const refresh = (adsManager: AdsManager, options?: Record<string, unknown>) => {
  const settings = { ...DEFAULT_OPTIONS, ...options };
  const {
    viewableThreshold,
    refreshInterval,
    runAuctionOnRefresh,
    enableForAll,
    refreshLimit,
  } = settings;
  const visibilityLog: Record<string, VisibilityLog> = {};
  const refreshTimers: Record<string, number> = {};
  const refreshAdUnit = (adUnit: AdUnit) => {
    const { slot, placeholder } = adUnit;
    if (!slot || !placeholder) {
      Object.values(refreshTimers).forEach(() => {
        window.clearTimeout(refreshTimers[placeholder]);
      });
      return;
    }

    window.clearTimeout(refreshTimers[placeholder]);
    if (visibilityLog[placeholder].isVisible) {
      adsManager.googleTagProvider.run(() => {
        adsManager.googleTagProvider.setKeyValues(slot, { [REFRESH_KEY]: REFRESH_KEY_VALUE });
        adsManager.requestAds([adUnit]);
        const refreshCount = (visibilityLog[placeholder].refreshCount || 0) + 1;
        visibilityLog[placeholder] = {
          ...visibilityLog[placeholder],
          startedAt: 0,
          visibleFor: 0,
          refreshCount,
        };
        adUnit.refreshCount = refreshCount;
        const adUnitRefreshLimit = adUnit.refreshLimit || refreshLimit;
        if (adUnitRefreshLimit > 0 && visibilityLog[placeholder].refreshCount === adUnitRefreshLimit) {
          adUnit.setProps({ refresh: false });
          return logger.log('reached the refresh limit for ', placeholder);
        }

        refreshTimers[placeholder] = window.setTimeout(() => {
          window.clearTimeout(refreshTimers[placeholder]);
          if (runAuctionOnRefresh) {
            adsManager.setWinningBids({ [placeholder]: {} });
          }
          refreshAdUnit(adUnit);
        }, adUnit.refreshInterval || refreshInterval);
        return null;
      });
    }
  };
  const startCounting = (adUnit: AdUnit) => {
    const { placeholder } = adUnit;
    const log = visibilityLog[placeholder] || DEFAULT_LOG;
    if (log.isVisible && log.startedAt) {
      return;
    }
    const adUnitRefreshInterval = adUnit.refreshInterval || settings.refreshInterval;
    visibilityLog[placeholder] = {
      ...log,
      startedAt: performance.now(),
      isVisible: true,
    };
    refreshTimers[placeholder] = window.setTimeout(() => refreshAdUnit(adUnit), adUnitRefreshInterval - (log.visibleFor || 0));
  };
  const stopCounting = ({ placeholder = '' }: Partial<AdUnit>) => {
    const log = visibilityLog[placeholder];
    if (typeof log === 'undefined') {
      return;
    }
    window.clearTimeout(refreshTimers[placeholder]);
    if (!log.isVisible || !log.startedAt) {
      return;
    }
    visibilityLog[placeholder] = {
      ...log,
      startedAt: 0,
      visibleFor: performance.now() - log.startedAt + log.visibleFor,
      isVisible: false,
    };
    logger.log('visibility logged', visibilityLog[placeholder]);
  };

  const shouldEnableRefresh = (adUnit: AdUnit) => {
    return enableForAll || adUnit.refresh;
  };

  const startListeningToVisibilityChange = () => adsManager.googleTagProvider.run(() => {
    adsManager.googleTagProvider.listen('slotVisibilityChanged', (event: googletag.events.Event) => {
      const { slot, inViewPercentage } = event as googletag.events.SlotVisibilityChangedEvent;
      if (!slot) {
        return;
      }
      const adUnit: AdUnit | undefined = adsManager.adUnitsRegistry.getAdUnit(slot.getSlotElementId());
      if (!adUnit || !shouldEnableRefresh(adUnit)) {
        return;
      }

      adsManager.adUnitsRegistry.updateAdUnitInViewPercentage(inViewPercentage, adUnit.adUnitHierarchy);

      if (inViewPercentage > (adUnit.viewableThreshold || viewableThreshold)) {
        startCounting(adUnit);
      } else {
        stopCounting(adUnit);
      }
    });
  });
  return startListeningToVisibilityChange();
};
