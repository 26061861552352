import { AssetType, LogomorphCampaign, VideoCampaign, WidgetDirectCampaign } from 'campaign-manager';
import { PageData } from 'injector';
import { VideoAuctionAdUnit, logger, BIDDER_ADAPTERS_MAP } from 'ads-manager';
import { domAPI } from 'mm-commercial-utils';
import { NO_CAMPAIGN_MANAGER } from 'injector/src/Injector';
import { AdSizes, HeaderBiddingConfiguration, RawSitePolicy, SitePolicyService } from './services/sitePolicy.service';
import { CampaignManagerService, RawCampaign } from './services/campaignManager.service';
import { EventStreamer } from './eventStreaming/EventStreamer';
import { VoltaxPlayerService } from './services/voltaxPlayer.service';
import { InjectorService } from './services/injector.service';
import { GtmService } from './services/gtm.service';
import { AdsManagerService } from './services/adsManager.service';
import { AdUnitsApiProvider } from './providers/adUnitsApiProvider';

function importCommonModules(geo: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore next line
  Promise.all([import('prebid.js/modules/mass'), import('prebid.js/modules/schain'), import('prebid.js/modules/priceFloors'), import('prebid.js/modules/sharedIdSystem'), import('prebid.js/modules/pubCommonId'), import('prebid.js/modules/uid2IdSystem'), import('prebid.js/modules/unifiedIdSystem'), import('prebid.js/modules/identityLinkIdSystem'), import('prebid.js/modules/pubProvidedIdSystem'), import('prebid.js/modules/userId')])
    .then(modules => logger.log('Prebid common modules loaded', modules))
    .catch(err => {
      logger.error('Error loading prebid common modules', err);
    });
  // eslint-disable-next-line no-underscore-dangle
  if ((window as any).__tcfapi) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore next line
    Promise.all([import('prebid.js/modules/consentManagement'), import('prebid.js/modules/consentManagementUsp'), import('prebid.js/modules/gdprEnforcement')])
      .then(modules => logger.log('Prebid consent modules loaded', modules))
      .catch(err => {
        logger.error('Error loading prebid consent modules', err);
      });
  }
  if (['AU', 'CA', 'NZ', 'SP', 'TW', 'US'].includes(geo)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore next line
    import('prebid.js/modules/identityLinkIdSystem')
      .then(modules => logger.log('Prebid LiveRamp loaded', modules))
      .catch(err => {
        logger.error('Error loading Prebid LiveRamp modules', err);
      });
  }
  const domainName = window.location.hostname;
  if (domainName.includes('theplayerstribune.com')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore next line
    Promise.all([import('prebid.js/modules/hadronIdSystem'), import('prebid.js/modules/rtdModule'), import('prebid.js/modules/hadronRtdProvider')])
      .then(modules => logger.log('Prebid modules loaded', modules))
      .catch(err => {
        logger.error('Error loading prebid modules', err);
      });
  }
}

const COMMERCIAL_API_READY_EVENT = 'commercialApiReady';
const PAGE_TYPES_TO_EXCLUDE_WIDGET = ['homepage', 'feedpage'];
const PREBID_ADAPTERS_CONTAINER = 'adaptersReady';
function injectVoltaxPlayerAssets() {
  domAPI.onDOMReady(() => {
    VoltaxPlayerService.getInstance().injectAssets({
      [AssetType.Video]: CampaignManagerService.getInstance().getCampaignByAssetType(AssetType.Video) as VideoCampaign | null,
      [AssetType.VideoDirect]: CampaignManagerService.getInstance().getVideoDirectCampaigns(),
    });
  });
}

async function injectSitePolicyAssets(pageData: PageData) {
  domAPI.onDOMReady(async () => {
    const sitePolicy = SitePolicyService.getInstance().getSitePolicy();
    if (pageData.pageType && PAGE_TYPES_TO_EXCLUDE_WIDGET.includes(pageData.pageType.toLowerCase())) {
      sitePolicy.enableWidgets = false;
    }
    if (pageData.tags.includes(NO_CAMPAIGN_MANAGER)) {
      sitePolicy.enableWidgets = false;
    }
    await InjectorService.getInstance().injectAssets(sitePolicy, {
      [AssetType.WidgetDirect]: CampaignManagerService.getInstance().getCampaignByAssetType(AssetType.WidgetDirect) as WidgetDirectCampaign[],
      [AssetType.Logomorph]: CampaignManagerService.getInstance().getCampaignByAssetType(AssetType.Logomorph) as LogomorphCampaign,
    }, pageData);
    GtmService.getInstance().pushToDataLayer({ mmSitePolicyId: sitePolicy.id, mmSitePolicyName: sitePolicy.name, mmSessionId: EventStreamer.sessionId });
    GtmService.getInstance().pushToDataLayer({ GA_event_category: 'Commercial', GA_event_action: 'Commercial API Loaded', mmSitePolicyId: sitePolicy.id, mmSitePolicyName: sitePolicy.name, mmSessionId: EventStreamer.sessionId }, 'GTM event To GA');
  });
}

function getPageDataObj(pageData: PageData) {
  const obj: Record<string, unknown> = {
    country: pageData.country,
    state: pageData.state,
    platform: pageData.platform,
    pageType: pageData.pageType,
    operatingSystem: pageData.operatingSystem,
    trafficSource: pageData.trafficSource,
    commercialTags: pageData.tags.toString(),
    editorialTags: pageData.distributionChannels.toString(),
    language: pageData.language,
    mmUserIdentifier: pageData.mmUserIdentifier,
    eventCallback: GtmService.getInstance().registerGTMContainer,
    eventTimeout: 3000,
  };
  if (pageData.experiment) {
    obj.experiment = pageData.experiment;
  }
  return obj;
}

function sendPageData(pageData: PageData) {
  GtmService.getInstance().pushToDataLayer(getPageDataObj(pageData), 'promotions data ready');
}

function importBidderAdapters() {
  const headerBiddingConfiguration = SitePolicyService.getInstance().headerBidding;
  if (headerBiddingConfiguration.prebid?.bidders.banner) {
    const bidderNames = headerBiddingConfiguration.prebid.bidders.banner.map(bidderConfiguration => bidderConfiguration.bidder);
    const adaptersToLoad = bidderNames.map(bidderName => import(`prebid.js/modules/${BIDDER_ADAPTERS_MAP[bidderName] || bidderName}BidAdapter`));
    return Promise.all(adaptersToLoad)
      .then(() => GtmService.getInstance().registerGTMContainer(PREBID_ADAPTERS_CONTAINER))
      .catch(err => {
        logger.error('Error loading adapter', err);
      });
  }
  if (!GtmService.getInstance().containers.includes(PREBID_ADAPTERS_CONTAINER)) {
    GtmService.getInstance().registerGTMContainer(PREBID_ADAPTERS_CONTAINER);
  }

  return Promise.resolve();
}

function subscribeToGtm(pageData: PageData) {
  GtmService.getInstance().subscribeToContainers([POLICY_GTM_CONTAINER_ID], importBidderAdapters);
  GtmService.getInstance().subscribeToContainers([...PROMOTION_GTM_CONTAINER_IDS], injectVoltaxPlayerAssets);
  GtmService.getInstance().subscribeToContainers([POLICY_GTM_CONTAINER_ID, ...PROMOTION_GTM_CONTAINER_IDS, PREBID_ADAPTERS_CONTAINER], () => injectSitePolicyAssets(pageData));
}

function dispatchReadyEvent() {
  const commercialApiReadyEvent = new Event(COMMERCIAL_API_READY_EVENT);
  window.dispatchEvent(commercialApiReadyEvent);
}

function initCommercialApi() {
  PROMOTION_GTM_CONTAINER_IDS.forEach(GtmService.getInstance().loadScript);
  AdUnitsApiProvider.getAdUnitsData(domAPI.getSiteName());
  InjectorService.getInstance().getPageData().then((pageData: PageData) => {
    importCommonModules(pageData.country);
    sendPageData(pageData);
    EventStreamer.sendSessionEvent(pageData);
    subscribeToGtm(pageData);
    dispatchReadyEvent();
  });
}

export function setCampaign(campaign: RawCampaign) {
  CampaignManagerService.getInstance().addCampaign(campaign);
}

export function setSitePolicy(sitePolicy: RawSitePolicy) {
  SitePolicyService.getInstance().setSitePolicy(sitePolicy);
}

export function setHeaderBidding(bidders: HeaderBiddingConfiguration) {
  SitePolicyService.getInstance().setHeaderBidding(bidders);
}

export async function renderDisplayAd(adUnitPath: string, sizes: AdSizes, placeholderId: string) {
  return AdsManagerService.getInstance().renderDisplayAd(adUnitPath, sizes, placeholderId);
}

export async function runVideoAuction(videoAuctionAdUnits: VideoAuctionAdUnit[], timeout: number) {
  return AdsManagerService.getInstance().runVideoAuction({
    videoAuctionAdUnits,
    timeout,
  });
}

initCommercialApi();
