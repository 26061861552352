type CampaignItem = {
  assetType: StaticAssetType,
  assetId: string,
  campaignGroupId: string,
  campaignId: string,
}
enum StaticAssetType {
  WidgetDirect = 'widget-direct',
  Logomorph = 'logomorph',
  Display = 'display'
}
type EventName = string;

type Argument = InjectArticleBlockByIndexObject[]
    | ((viewabilityArticleBlockEventObject: ViewabilityArticleBlockEventObject) => void)
    | ((pageDataObject: PageDataObject) => void)
    | ((dataId: string) => void)
    | (() => void)
    | AnalyticsObject
    | InjectVideoLogoObject
    | InjectAdPlaceholdersObject;

type ClientAPI = (EventName | Argument)[];

declare global {
    interface Window {
        mmClientApi: ClientAPI;
    }
}

export interface InjectArticleBlockByIndexObject {
    html: string;
    index: number;
    dataId?: string;
    script?: string;
}

export interface ViewabilityArticleBlockEventObject {
    id: string;
    index: number;
    templateName: string;
}

interface InjectVideoLogoObject {
    videoPath: string;
}

interface InjectAdPlaceholderObject {
    id: string;
    onSuccess: () => void;
}

export type InjectAdPlaceholdersObject = Record<string, InjectAdPlaceholderObject>;

interface AnalyticsObject {
    event_category: string;
    event_action: string;
    event_label: string;

    [key: string]: string;
}

export interface PageDataObject {
    siteName: string;
    countryCode: string;
    blockNames: string[];
    blockMMDataIDs: string[];
    language: string;
    tags: string[] | null;
    usState: string;
    platform: string;
    trafficSource: string;
    distributionChannels: string[];
    mmUserIdentifier: string;
    experiments: string[];
    pageType: string;
    articleId: string;
    trafficSourceAndId: string;
    sessionId: string;
    affiliateId: string;
}

const getClientAPI = () => {
  window.mmClientApi = window.mmClientApi || [];
  return window.mmClientApi;
};

const injectArticleBlockByIndexArray = (injectArticleBlockByIndexObjects: InjectArticleBlockByIndexObject[]) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('injectArticleBlockByIndexArray', injectArticleBlockByIndexObjects);
};

const injectArticleBlockByIndexArrayAsync = (injectArticleBlockByIndexObjects: InjectArticleBlockByIndexObject[]) => {
  const mmClientApi = getClientAPI();
  const dataIds: string[] = [];
  return new Promise(resolve => {
    mmClientApi.push('injectArticleBlockByIndexEvent', (dataId: string) => {
      dataIds.push(dataId);
      if (dataIds.length === injectArticleBlockByIndexObjects.length) {
        return resolve(dataIds);
      }
      return null;
    });
    mmClientApi.push('injectArticleBlockByIndexArray', injectArticleBlockByIndexObjects);
  });
};

const viewabilityArticleBlockEvent = (callback: (viewabilityArticleBlockEventObject: ViewabilityArticleBlockEventObject) => void) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('viewabilityArticleBlockEvent', callback);
};

const injectVideoLogoSuccessfully = (callback: () => void) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('injectVideoLogoSuccessfully', callback);
};

const getPageData = (callback: (pageDataObject: PageDataObject) => void) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('getPageData', callback);
};

const getPageDataAsync = () => {
  const mmClientApi = getClientAPI();
  return new Promise<PageDataObject>(resolve => mmClientApi.push('getPageData', resolve));
};

const injectArticleBlockByIndexEvent = (callback: (dataId: string) => void) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('injectArticleBlockByIndexEvent', callback);
};

const analytics = (analyticsObject: AnalyticsObject) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('analytics', analyticsObject);
};

const injectVideoLogo = (injectVideoLogoObject: InjectVideoLogoObject) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('injectVideoLogo', injectVideoLogoObject);
};

const injectAdPlaceholders = (placeholders: InjectAdPlaceholdersObject) => {
  const mmClientApi = getClientAPI();
  mmClientApi.push('injectAdPlaceholders', placeholders);
};

const setWidgetEmbedEvent = (blockDataId: string, widgetCampaignItem: CampaignItem, widgetEmbedEventCallback: (item: CampaignItem) => void) => {
  injectArticleBlockByIndexEvent(dataId => {
    if (dataId === blockDataId) {
      return widgetEmbedEventCallback(widgetCampaignItem);
    }
    return null;
  });
};

const setWidgetViewableEvent = (blockDataId: string, widgetCampaignItem: CampaignItem, widgetImpressionEventCallback: (item: CampaignItem) => void) => {
  viewabilityArticleBlockEvent(viewabilityArticleBlockEventObject => {
    if (viewabilityArticleBlockEventObject.id === blockDataId) {
      return widgetImpressionEventCallback(widgetCampaignItem);
    }
    return null;
  });
};

const setLogomorphEmbedEvent = (logomorphCampaignItem: CampaignItem, logomorphEmbedEventCallback: (item: CampaignItem) => void) => {
  injectVideoLogoSuccessfully(() => {
    return logomorphEmbedEventCallback(logomorphCampaignItem);
  });
};

export const clientAPI = {
  injectAdPlaceholders,
  injectArticleBlockByIndexArray,
  injectArticleBlockByIndexArrayAsync,
  viewabilityArticleBlockEvent,
  getPageData,
  getPageDataAsync,
  injectArticleBlockByIndexEvent,
  analytics,
  injectVideoLogo,
  setWidgetEmbedEvent,
  setWidgetViewableEvent,
  setLogomorphEmbedEvent,
};
