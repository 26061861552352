import { AdsManagerModuleOptions, genericPreset } from './modules/modulePresets/genericPreset';
import { AdsManager, AdsManagerModuleConfig, PageData } from './adsManager/AdsManager';
import { AdsManagerSettings, DEFAULT_ADS_MANAGER_OPTIONS } from './settings/settings';
import { getTcfData, isValidConsent, TcfData } from './utils/tcf.utils';

export { AdsManagerModuleOptions } from './modules/modulePresets/genericPreset';
export { AdsManager, AdsManagerModuleConfig, VideoAuctionAdUnit } from './adsManager/AdsManager';
export {
  AdUnit,
  Sticky,
  DfpKeyValues,
  VIEWABILITY_KEY,
  DEFAULT_VIEWABILITY,
  formatViewabilityTargetingValue,
  DEFUALT_LAZY_LOAD_THRESHOLD,
} from './adUnits/AdUnit';
export { AdsManagerSettings } from './settings/settings';
export { BidderConfig, PrebidServiceConfig } from './headerBidding/prebid/PrebidService';

export { logger } from './utils/logger';
export { BIDDER_ADAPTERS_MAP } from './headerBidding/prebid/prebidService.constants';

export function getModules(modulesSettings: Partial<AdsManagerModuleOptions>): AdsManagerModuleConfig[] {
  return genericPreset(modulesSettings);
}

export function getSettings(options?: Partial<AdsManagerSettings>): AdsManagerSettings {
  return {
    ...DEFAULT_ADS_MANAGER_OPTIONS,
    ...options,
  };
}

export async function initAdsManager(modules: AdsManagerModuleConfig[], settings: AdsManagerSettings, pageData: PageData, isPlatform = true): Promise<AdsManager> {
  return new Promise(resolve => {
    const adsManager = AdsManager.getInstance();
    if (isPlatform) {
      getTcfData((data: TcfData | null) => {
        if (isValidConsent(data)) {
          adsManager.init(modules, settings, pageData).then(() => resolve(adsManager));
        }
      });
    } else {
      adsManager.init(modules, settings, pageData).then(() => resolve(adsManager));
    }
  });
}
