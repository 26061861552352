import {
  DYNAMIC_ASSET_PLACEHOLDER_PREFIX,
  PredefinedDynamicAsset,
  PredefinedAssetsByPosition,
  AssetPosition,
} from '../Injector';

export function createPlaceholderHTML(
  id: string | number,
  html: string,
  verticalMargin?: string,
) {
  const styles = verticalMargin
    ? `margin-top: ${verticalMargin}; margin-bottom: ${verticalMargin}`
    : '';
  return `<div id=${id} style="${styles}">${html}</div>`;
}

export function getDynamicAssetPlaceholderId(
  position: number,
  dynamicPlaceholderIndex = 1,
) {
  return `${DYNAMIC_ASSET_PLACEHOLDER_PREFIX}-${dynamicPlaceholderIndex}-${position}`;
}

function isMiddlePositionInTop(positionsListLength: number, predefinedAssets: PredefinedDynamicAsset[]) {
  return positionsListLength === 2 && predefinedAssets.length === 2 && predefinedAssets[0].item.assetPosition === AssetPosition.Middle;
}

export function getPositionIndexForAsset(
  position: AssetPosition,
  positions: number[],
  predefinedAssets: PredefinedDynamicAsset[],
) {
  switch (position) {
    case AssetPosition.Top:
      return 0;
    case AssetPosition.Middle:
    {
      if (isMiddlePositionInTop(positions.length, predefinedAssets)) {
        return 0;
      }
      const middle = Math.floor(positions.length / 2);
      return middle;
    }
    case AssetPosition.Bottom:
      return positions.length - 1;
    default:
      return 0;
  }
}

export function getValidPredefinedAssetsList(
  predefinedAssets: PredefinedAssetsByPosition,
  positionsListLength: number,
) {
  const validPredefinedAssetsList = [] as PredefinedDynamicAsset[];
  const positions = [AssetPosition.Top, AssetPosition.Middle, AssetPosition.Bottom];
  positions.forEach(position => {
    if (predefinedAssets[position] && validPredefinedAssetsList.length + 1 <= positionsListLength) {
      validPredefinedAssetsList.push(predefinedAssets[position] as PredefinedDynamicAsset);
    }
  });
  return validPredefinedAssetsList;
}
