import { defaultTrackingMethod, rumSessionTracking, TrackingMethod } from './tracking.utils';
import { EVENTS } from '../../settings/settings';
import { AdsManager } from '../../adsManager/AdsManager';

export const DEFAULT_TRACKING_OPTIONS = {
  priceTargetingKey: 'hb_pb',
  trackingDelay: 3000,
  trackingMethods: [defaultTrackingMethod],
  sendSession: false,
};

export const trackingModule = (adsManager: AdsManager, options?: Record<string, unknown>) => {
  const settings = { ...DEFAULT_TRACKING_OPTIONS, ...options };
  const { priceTargetingKey, trackingDelay, trackingMethods, sendSession } = settings;

  const sendTrackingData = (event: googletag.events.SlotRenderEndedEvent) => {
    const { slot, isEmpty, lineItemId, sourceAgnosticLineItemId } = event;
    const price = slot.getTargeting(priceTargetingKey);
    const elementId = slot.getSlotElementId();
    const adUnitPath = slot.getAdUnitPath();
    const winningBid = adsManager.winningBids[slot.getSlotElementId()];
    const prebidWon = !!winningBid;
    const prebidHighestBid = prebidWon && winningBid.adserverTargeting ? parseFloat(winningBid.adserverTargeting[priceTargetingKey]) : (price && parseFloat(price[0])) || 0;
    const getPricePrediction = (cpm: number): number => (cpm > 0 ? cpm + 0.01 : 0);
    const blockNumberExtractedFromId: string | undefined = elementId.match(/[0-9]+/gi)?.pop();
    const getPosition = (): number => (blockNumberExtractedFromId ? parseInt(blockNumberExtractedFromId, 10) : 0);
    const trackingData = {
      adUnitPath,
      lineItemId: lineItemId || sourceAgnosticLineItemId,
      fill: !isEmpty,
      prebidWon,
      price: prebidWon ? prebidHighestBid : getPricePrediction(prebidHighestBid),
      position: getPosition(),
      elementId: slot.getSlotElementId(),
    };
    trackingMethods.forEach((method: TrackingMethod) => method(trackingData));
  };

  const onSlotRenderEnded = (event: googletag.events.Event) => {
    const { slot } = event;
    if (adsManager.adUnitsRegistry.getAdUnit(slot.getSlotElementId())) {
      const trackingTimer = window.setTimeout(() => {
        window.clearTimeout(trackingTimer);
        sendTrackingData(event as googletag.events.SlotRenderEndedEvent);
      }, trackingDelay);
    }
  };

  adsManager.googleTagProvider.run(
    () => adsManager.googleTagProvider.listen('slotRenderEnded', onSlotRenderEnded),
  );

  if (sendSession) {
    adsManager.subscribe(EVENTS.ON_INIT, () => {
      rumSessionTracking();
    });
  }
};
