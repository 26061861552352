// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
// @ts-ignore
import prebid from 'prebid.js';
import { getOperatingSystem, isChrome } from 'mm-commercial-utils';
import { HeaderBiddingService } from '../HeaderBiddingService';
import { logger } from '../../utils/logger';
import { BidderFactory } from './BidderFactory';
import { FAILSAFE_TIMEOUT } from '../headerBiddingService.constants';
import {
  BIDDERS,
  DEFAULT_PREBID_CONFIG,
  hadronUserId,
  OPENX_ANALYTICS_OPTIONS,
  PREBID_TIMEOUT,
  realTimeData,
  TBT_HOSTNAME,
} from './prebidService.constants';
import { AdUnit } from '../../adUnits/AdUnit';
import { VideoAdUnit } from '../../adUnits/VideoAdUnit';
import { PageData } from '../../adsManager/AdsManager';

export type BidderParams = Record<string, string | number | number[]>;
export type BidderFloorPrice = string | number;

export type MediaType = 'banner' | 'video';
export type SchemaField = 'bidder' | 'mediaType';

export interface PriceFloorsConfiguration {
  enforcement: {
    floorDeals: boolean;
  },
  floorMin?: number;
  data: {
    floorProvider?: string;
    skipRate: number;
    currency: string;
    schema: {
      delimiter: string,
      fields: SchemaField[];
    };
    modelVersion: string;
    values: {
      banner?: number;
      video?: number;
    };
  },
  additionalSchemaFields: {
    bidder: (bidRequest: BidRequest) => void;
  },
}

export interface PrebidAdUnit {
  code: string;
  mediaTypes: Record<string, unknown>;
  bids: BidRequest[];
}

export interface PrebidBid {
  adUnitCode: string;
  bidderCode: string;
  cpm: number;
  adserverTargeting: Record<string, string>;
}

type PrebidGranularity = 'high' | 'medium' | 'low';
type CustomPriceGranularityOptions = 'precision' | 'max' | 'increment';

interface CustomPrebidPriceGranularity {
  buckets: Record<CustomPriceGranularityOptions, number>[];
}

interface PrebidTargetingControls {
  alwaysIncludeDeals: boolean;
  allowTargetingKeys: string[];
}

interface PrebidMASSConfiguration {
  renderUrl: string;
}

export interface PrebidServiceConfig {
    pageUrl?: string;
    bidders: Record<MediaType, BidderConfig[]>;
    useBidCache?: boolean;
    priceGranularity?: PrebidGranularity;
    mediaTypePriceGranularity?: Record<MediaType, CustomPrebidPriceGranularity>;
    enableSendAllBids?: boolean;
    targetingControls?: PrebidTargetingControls;
    biddersConfig?: Record<string, Partial<PrebidServiceConfig>>;
    mass?: PrebidMASSConfiguration;
    cache?: { url: string };
    consentManagement?: {
        gdpr: {
          cmpApi: string;
          timeout?: number;
          defaultGdprScope?: boolean;
          allowAuctionWithoutConsent?: boolean;
          rules?: Record<string, string | number | boolean>[];
        };
    };
    floors?: PriceFloorsConfiguration;
    userSync?: {
        userIds: Record<string, unknown>[];
        syncDelay: number;
    },
    bidCpmAdjustment?: (bidCpm: number) => number;
}

export interface PrebidRequest {
  bidsBackHandler: (bids: Record<string, { bids?: PrebidBid[] }>, timeout: boolean, auctionId: string) => void;
  timeout: number;
  adUnits?: PrebidAdUnit[];
}

export interface BidderConfig {
  bidder: string;
  params: BidderParams[];
  floorPrice: BidderFloorPrice,
}

export interface BidRequest {
  bidder: string;
  params: BidderParams;
}

export interface AuctionRequest {
  mediaTypes: Record<string, Record<string, unknown>>;
  bids: BidRequest[];
}

export interface BidderCreationParams {
  bidderConfig: BidderConfig;
  sizes: number[][];
}

export interface PrebidServiceSettings {
  adUnits: AdUnit[];

  onBidWon: (data: PrebidBid) => void;

  config: PrebidServiceConfig;
}

export class PrebidService implements HeaderBiddingService {
  adUnits: PrebidAdUnit[];

  biddersBack: boolean;

  adUnitTemplate: AuctionRequest | null = null;

  onBidWon: (data: PrebidBid) => void;

  config: PrebidServiceConfig;

  scriptTag: HTMLScriptElement | undefined = undefined;

  private videoBidRequestCounter = 0;

  constructor(settings: PrebidServiceSettings) {
    const { adUnits, onBidWon, config } = settings;
    logger.log('our prebid', prebid);
    logger.log('bidders config', config.bidders);
    this.biddersBack = false;
    this.onBidWon = onBidWon;
    this.config = { ...DEFAULT_PREBID_CONFIG, ...config };
    this.editConfigObj();
    this.adUnits = this.createAdUnits(adUnits);
  }

  biddersBackHandler: (bidderName: string, adUnits?: AdUnit[]) => void = () => null;

  listenToEvents = () => {
    prebid.onEvent('bidWon', (data: PrebidBid) => {
      return this.onBidWon && this.onBidWon(data);
    });
    prebid.onEvent('auctionInit', (data: Record<string, unknown>) => {
      return logger.log('Prebid Auction Init', data);
    });
    prebid.onEvent('auctionEnd', (data: Record<string, unknown>) => {
      return logger.log('Prebid Auction End', data);
    });
  };

  registerAdUnits = (adUnits?: AdUnit[]) => {
    const newUnits = adUnits ? this.createAdUnits(adUnits) : [];
    this.adUnits = [...this.adUnits, ...newUnits];
    return newUnits.length && prebid.addAdUnits(newUnits);
  };

  removeAdUnit(adUnit: AdUnit) {
    prebid.removeAdUnit(adUnit.adUnitHierarchy);
    this.adUnits = this.adUnits.filter(registeredAdUnit => adUnit.placeholder !== registeredAdUnit.code);
  }

  sendVideoBidsRequest = (videoAdUnits: VideoAdUnit[], timeout: number = PREBID_TIMEOUT, bidsBackHandler: (bids: string[]) => void) => {
    const newVideoAdUnits = this.createVideoAdUnits(videoAdUnits);
    prebid.addAdUnits(newVideoAdUnits);
    const requestObj: PrebidRequest = {
      bidsBackHandler: () => {
        const bidsResponse = videoAdUnits.map(videoAdUnit => {
          return prebid.getAdserverTargetingForAdUnitCodeStr(videoAdUnit.code);
        });

        bidsBackHandler(bidsResponse);
      },
      timeout,
      adUnits: newVideoAdUnits,
    };
    return prebid.requestBids(requestObj);
  };

  sendBidsRequest(adUnits?: AdUnit[], timeout: number = PREBID_TIMEOUT) {
    prebid.processQueue();
    prebid.initAdserverSet = false;
    this.biddersBack = false;
    const adUnitsCandidates = adUnits ? adUnits.map(this.createAdUnit) : Object.values(this.adUnits);
    const auctionUnits = adUnitsCandidates.filter(adUnit => adUnit.bids && adUnit.bids.length > 0);
    const requestObj: PrebidRequest = {
      bidsBackHandler: () => this.sendAdServerRequest(adUnits),
      timeout,
      adUnits: auctionUnits,
    };
    return auctionUnits ? prebid.requestBids(requestObj) : this.sendAdServerRequest();
  }

  enableAnalytics() {
    prebid.enableAnalytics([{
      provider: BIDDERS.OPENX,
      options: OPENX_ANALYTICS_OPTIONS,
    }]);
  }

  sendAdServerRequest = (adUnits?: AdUnit[]) => {
    if (!prebid.initAdserverSet) prebid.initAdserverSet = true;
    if (adUnits) {
      prebid.setTargetingForGPTAsync();
      this.biddersBack = true;
      this.biddersBackHandler('prebid', adUnits);
      logger.log('prebid bidders back');
    }
  };

  setConfig(config = this.config) {
    prebid.setConfig({
      ...config,
      floors: BidderFactory.getFloorPricesConfiguration(this.config.bidders),
    });
  }

  editConfigObj = () => {
    const domainName = window.location.hostname;
    if (domainName.includes(TBT_HOSTNAME)) {
      const updatedConfig: any = { ...this.config };
      if (updatedConfig.userSync) updatedConfig.userSync.userIds = [...updatedConfig.userSync.userIds, hadronUserId];
      this.config = {
        ...updatedConfig,
        realTimeData,
      };
    }
  };

  setBidderConfig(biddersConfig = this.config.biddersConfig) {
    prebid.setBidderConfig(biddersConfig);
  }

  setProps(props: Partial<HeaderBiddingService>) {
    Object.assign(this, props);
  }

  aliasBidders() {
    prebid.aliasBidder(BIDDERS.APPNEXUS, BIDDERS.BIGHAPPY);
    prebid.aliasBidder(BIDDERS.APPNEXUS, BIDDERS.OFTMEDIA);
  }

  onLoadIntentIq() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore next line
    (window as any).commercialIntentIq = new IntentIqObject({
      partner: 890910414,
      pbjs: prebid,
      ABTestingConfigurationSource: 'percentage',
      abPercentage: 95,
      manualWinReportEnabled: true,
      domainName: window.location.hostname,
    });
    return null;
  }

  loadIntentIqLibrary() {
    const intentIqScript = document.createElement('script');
    intentIqScript.setAttribute('src', 'https://cdn.mmctsvc.com/commercial-api/IIQUniversalID.js');
    document.body.appendChild(intentIqScript);
    intentIqScript.onload = () => this.onLoadIntentIq();
  }

  shouldLoadIntentIq(country: string) {
    return ['ios', 'macos', 'windows'].includes(getOperatingSystem()) && ['US', 'CA', 'AU', 'JP'].includes(country) && !isChrome();
  }

  initService(pageData: PageData) {
    prebid.processQueue();
    this.setConfig();
    this.setBidderConfig();
    if (this.shouldLoadIntentIq(pageData.country)) this.loadIntentIqLibrary();
    this.registerAdUnits();
    this.sendBidsRequest();
    this.listenToEvents();
    this.aliasBidders();
    setTimeout(() => {
      this.sendAdServerRequest();
    }, FAILSAFE_TIMEOUT);
  }

  private getVideoBidRequestCounter() {
    this.videoBidRequestCounter += 1;
    return this.videoBidRequestCounter;
  }

  private createAdUnit = (adUnit: AdUnit) => {
    this.adUnitTemplate = BidderFactory.getBannerBids(this.config.bidders.banner, adUnit.sizes);
    return this.adUnitTemplate && {
      ...this.adUnitTemplate,
      code: adUnit.placeholder,
      ortb2Imp: {
        ext: {
          gpid: `${adUnit.adUnitHierarchy}#${adUnit.placeholder}`,
          data: {
            pbadslot: `${adUnit.adUnitHierarchy}#${adUnit.placeholder}`,
          },
        },
      },
    };
  };

  private createVideoAdUnits = (config: VideoAdUnit[]) => {
    config.map(videoAdUnit => {
      videoAdUnit.code = `${videoAdUnit.code}-${this.getVideoBidRequestCounter()}`;
      return videoAdUnit;
    });
    return BidderFactory.getVideoBids(this.config.bidders.video, config);
  };

  private createAdUnits = (adUnits: AdUnit[]) => adUnits.reduce((accumulator: PrebidAdUnit[], adUnit) => {
    const prebidAdUnit = this.createAdUnit(adUnit);
    return prebidAdUnit ? [...accumulator, prebidAdUnit] : accumulator;
  }, []);
}
