import { STATIC_ASSET_PLACEHOLDER_PREFIX, StaticAssetType, PredefinedAssetsByPosition } from 'injector';
import campaignManager, { LogomorphCampaign, WidgetDirectCampaign } from 'campaign-manager';
import { DEFAULT_VIEWABILITY, formatViewabilityTargetingValue, VIEWABILITY_KEY } from 'ads-manager';
import { domAPI } from 'mm-commercial-utils';
import {
  AdUnit,
  DynamicAssets,
  RawAdUnit,
  RawAssets,
  RawSitePolicy,
  Selector,
  SelectorType,
  SITE_POLICY_DEFAULTS,
  StaticAssets,
} from '../services/sitePolicy.service';
import { AdUnitData, AdUnitsApiProvider } from '../providers/adUnitsApiProvider';

const DEFAULT_DYNAMIC_WIDGET_INDEX = 1;

const getToken = (token: string) => `##(${token})##`;

const TOKENS = {
  'SITE-NAME': domAPI.getSiteName(),
};

export function tokenizeString(stringForTokenization: string) {
  return Object.entries(TOKENS).reduce((accumulator, [token, value]) => {
    return accumulator.replace(getToken(token), value);
  }, stringForTokenization);
}

function getCssSelector(selector: Selector[]) {
  return selector.reduce((accumulator, selectorPart, index) => {
    const {
      type,
      value,
      position,
    } = selectorPart;
    let prefix = '#';
    const secondLevel = position ? `:nth-of-type(${position + 1})` : '';
    const firstLevelChildren = index > 0 ? ' > ' : '';
    switch (type) {
      case SelectorType.TagName:
        prefix = '';
        break;
      case SelectorType.ClassName:
        prefix = secondLevel ? '*.' : '.';
        break;
      default:
        break;
    }
    return `${accumulator}${firstLevelChildren}${prefix}${value}${secondLevel}`;
  }, '')
    .trim();
}

function convertAdUnitsToInjectorItems(adUnits: AdUnit[]) {
  return adUnits.map(() => ({
    payload: { html: '' },
    assetType: StaticAssetType.Display,
  }));
}

export function convertSitePolicyDynamicAssetsToInjectorConfig(dynamicAssets: DynamicAssets, enableAdsOnFirstContent: boolean) {
  const {
    adUnits,
    maxAssets,
    blocksBetweenAds,
    firstAdPosition,
    rootSelector,
  } = dynamicAssets;
  const rootCssSelector = rootSelector && getCssSelector(rootSelector);
  return {
    firstAdPosition,
    blocksBetweenAds,
    maxAssets,
    rootCssSelector,
    enableAdsOnFirstContent,
    assets: maxAssets === 0 ? [] : convertAdUnitsToInjectorItems(adUnits),
    predefinedAssets: {} as PredefinedAssetsByPosition,
  };
}

export function convertSitePolicyStaticAssetsToInjectorConfig(staticAssets: StaticAssets, callback?: (adUnit: AdUnit) => (placeholderId: string) => void) {
  const { adUnits } = staticAssets;
  const assets = adUnits.map((adUnit: AdUnit, index) => {
    const {
      selector,
      path,
    } = adUnit;
    const placeholderId = `${STATIC_ASSET_PLACEHOLDER_PREFIX}-${index}`;
    return {
      payload: {
        html: `<div data-path="${path}" id="${placeholderId}"></div>`,
        selector: getCssSelector(selector),
        dataId: placeholderId,
      },
      callback: callback ? callback(adUnit) : undefined,
      assetType: StaticAssetType.Display,
    };
  });
  return { assets };
}

export function convertLogomorphCampaignToInjectorItem(logomorphCampaign: LogomorphCampaign) {
  return {
    payload: logomorphCampaign.asset.payload,
    assetType: StaticAssetType.Logomorph,
    assetId: logomorphCampaign.asset.id,
    campaignGroupId: logomorphCampaign.groupId,
    campaignId: logomorphCampaign.id,
  };
}

export function convertWidgetCampaignToPredefinedDynamicAsset(widgetCampaign: WidgetDirectCampaign) {
  return {
    index: DEFAULT_DYNAMIC_WIDGET_INDEX,
    item: {
      payload: {
        ...campaignManager.stripScriptsFromPayload(widgetCampaign.asset.payload),
        dataId: widgetCampaign.id,
      },
      assetId: widgetCampaign.asset.id,
      assetType: StaticAssetType.WidgetDirect,
      assetPosition: widgetCampaign.asset.position,
    },
    campaignGroupId: widgetCampaign.groupId,
    campaignId: widgetCampaign.id,
  };
}

function formatAdUnits(adUnitsData: AdUnitData, adUnits: RawAdUnit[]) {
  return adUnits.map(adUnit => {
    const selectedAdUnit = adUnitsData[adUnit.templateId];
    return {
      ...adUnit,
      path: selectedAdUnit?.path || tokenizeString(adUnit.path),
      dfpKeyValues: { [VIEWABILITY_KEY]: selectedAdUnit?.viewability || formatViewabilityTargetingValue(DEFAULT_VIEWABILITY) },
    };
  });
}

export function formatAssets(assets: RawAssets) {
  const { dynamic: dynamicAssets, static: staticAssets } = assets;
  const { adUnits: dynamicAdUnits } = dynamicAssets;
  const { adUnits: staticAdUnits } = staticAssets;
  const formattedDynamicAdUnits = formatAdUnits(AdUnitsApiProvider.adUnitsData, dynamicAdUnits);
  const formattedStaticAdUnits = formatAdUnits(AdUnitsApiProvider.adUnitsData, staticAdUnits);
  return {
    dynamic: {
      ...dynamicAssets,
      adUnits: formattedDynamicAdUnits,
    },
    static: {
      ...staticAssets,
      adUnits: formattedStaticAdUnits,
    },
  };
}

export function formatSitePolicy(sitePolicy: RawSitePolicy) {
  const { assets } = sitePolicy;
  const formattedAssets = formatAssets(assets);
  const { dynamic } = formattedAssets;
  const {
    sizes,
    adUnits,
  } = dynamic;
  const selector = [{
    type: SelectorType.TagName,
    value: '',
    position: 0,
  }];
  const dynamicDefaults = SITE_POLICY_DEFAULTS.assets.dynamic;
  return {
    ...SITE_POLICY_DEFAULTS,
    ...sitePolicy,
    assets: {
      ...formattedAssets,
      dynamic: {
        ...dynamic,
        rootSelector: dynamic.rootSelector || dynamicDefaults.rootSelector,
        maxAssets: adUnits.length > 0 ? dynamic.maxAssets : dynamicDefaults.maxAssets,
        firstAdPosition: dynamic.firstAdPosition || dynamicDefaults.firstAdPosition,
        blocksBetweenAds: dynamic.blocksBetweenAds || dynamicDefaults.blocksBetweenAds,
        adUnits: [
          ...adUnits.map(adUnit => ({
            ...{
              sizes,
              selector,
            },
            ...adUnit,
          })),
        ],
      },
    },
  };
}
