import { AdsManager } from '../../adsManager/AdsManager';
import { EVENTS } from '../../settings/settings';

const getIntersectionHandler = (adsManager: AdsManager) => (entries: IntersectionObserverEntry[]) => entries.forEach(entry => {
  const adUnit = adsManager.adUnitsRegistry.getAdUnit(entry.target.id);
  if (!adUnit) {
    return;
  }
  const { isDisplayed, triggerAuction } = adUnit;
  const enteringViewport = entry.isIntersecting && entry.intersectionRatio >= 0.1;
  const shouldTriggerAuction = !isDisplayed && triggerAuction && enteringViewport;
  if (shouldTriggerAuction) {
    adUnit.triggerAuction = false;
    adsManager.requestAds([adUnit]);
  }
});

export const coreLoader = (adsManager: AdsManager) => {
  adsManager.adUnitsRegistry.initAdUnitsIntersectionObserver(getIntersectionHandler(adsManager), adsManager.settings.lazyLoadThreshold);
  adsManager.subscribe(EVENTS.ON_ADUNIT_REGISTERED, () => {
    adsManager.adUnitsRegistry.uniquePlaceholders.forEach(adUnit => {
      const shouldTriggerAuction = adUnit.isOneOnOne && adUnit.triggerAuction;
      if (shouldTriggerAuction) {
        adUnit.triggerAuction = false;
        adsManager.requestAds([adUnit]);
      }
    });
  });
};
